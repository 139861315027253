import React, { Component } from 'react';
import { Select, FormControl, MenuItem, InputLabel } from '@mui/material';

/* 
    How to render 
    <LoadDropDown
      record={record}
      defaultValue={this.props.filterValues[record.dbname] || ''}
      callback={(event) => this.props.filterData(event)}
      ftype={record.ftype[0]}
    />

    *** Defination of fields:
    record: Object {label:"Value to be Presented", dbName:"name in data object",ftype:"to have an object of format type"} 
    defaultValue: The value to be selected by default
    callback: "Event to occur on change of dropdown"
    ftype:"Particular field ftype Format type object
    {
      optionType:"Defines what type of Values are to be Populated['object'/'array']",
      optionMap: this Field is mandatory only when optionType=>object 
      {
        label: "Name of the field whose value should be printed as menu label"
        value: "Name of the Field whose value should be printed as manu value"
      }
    }
    "
 */
class LoadDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
         

        {this.props.disabled && this.props.defaultValue ? (

          this.props.ftype.options.map((type) =>
            this.props.ftype.optiontype === 'object' ? (
              this.props.defaultValue &&
              type[this.props.ftype.optionMap['value']].toString().trim().toLowerCase() ===
                this.props.defaultValue.toString().trim().toLowerCase() ? (
                <p
                  key={`${this.props.record.label}${
                    this.props.ftype.optiontype === 'object'
                      ? type[this.props.ftype.optionMap['value']]
                      : type
                  }`}
                >
                  {type[this.props.ftype.optionMap['label']]}
                </p>
              ) : null
            ) : type.toString().toLowerCase() ===
              this.props.defaultValue.toString().toLowerCase() ? (
              <p
                key={`${this.props.record.label}${
                  this.props.ftype.optiontype === 'object'
                    ? type[this.props.ftype.optionMap['value']]
                    : type
                }`}
              >
                {type}
              </p>
            ) : null
          )
        ) : (
          <FormControl variant="outlined" sx={{ m: 1, width: '90%' }}>
            <InputLabel margin="normal">{this.props.ftype.placeholder}</InputLabel>
            {/* {console.log('LoadDropDown',this.props)} */}
            <Select
              label={this.props.record.label}
              name={this.props.record.dbname ? this.props.record.dbname : this.props.ftype.dbname}
              onChange={(e) => this.props.callback(e)}
              defaultValue={this.props.defaultValue}
            >
              <MenuItem value="None">
                <em>None</em>
              </MenuItem>

              {this.props.ftype.options &&
                this.props.ftype.options.map((type) => (
                  <MenuItem
                    key={`${this.props.record.label}${
                      this.props.ftype.optiontype === 'object'
                        ? type[this.props.ftype.optionMap['value']]
                        : type
                    }`}
                    value={
                      this.props.ftype.optiontype === 'object'
                        ? type[this.props.ftype.optionMap['value']]
                        : type
                    }
                  >
                    {this.props.ftype.optiontype === 'object'
                      ? type[this.props.ftype.optionMap['label']]
                      : type}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </>
    );
  }
}

export default LoadDropDown;
