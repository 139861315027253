import React, { Component } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  createTheme,
  Select,
  Checkbox,
  ListItemText,
  Autocomplete
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Postie from '../../helpers/postie.jsx';
import config from '../../constants/index.jsx';
import displayer from 'app/helpers/displayer';
import Auth from 'app/helpers/authorization';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { CopyAll, Delete, Edit, Remove, RoundedCorner, ViewAgenda } from '@mui/icons-material';
import isToday from 'date-fns/isToday/index.js';
import staticData from 'app/helpers/staticdata.jsx';
import swal from 'sweetalert';
import { post } from 'jquery';
import Authorization from 'app/helpers/authorization';
import ListRow from '../arena/Components/listRow.jsx';

class ReasonPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: ''
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ ...this.state, reason: value });
  };

  handleSumbitReason = async () => {
    const postData = {
      authtoken: Auth.getToken(),
      user_id: this.props.lead?.id,
      reason: this.state.reason
    };
    const url = config.BASE_API_URL + 'admin/rejectuser';
    let jsonData = await Postie.sendAsynchrnousCall(postData, url);
    if (jsonData.status === 'success') {
      this.props.closePopup();
    }
  };

  render() {
    const { lead } = this.props;
    return (
      <Dialog
        open={this.props.openPopup}
        //onClose={this.toggleDialog}
        fullWidth
        maxWidth="lg"
        disableBackdropClick
      >
        <DialogTitle>Please Write Reason for Rejection</DialogTitle>
        <DialogContent>
          <TextField
            type="text"
            label="Write reason"
            name="reason"
            onChange={this.handleInputChange}
            style={{ width: '90%' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closePopup} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleSumbitReason} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ReasonPopup;
