import React, { Component } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

/* 
    How to render 
     <LoadCheckList
      record={record}
      ftype={ftype}
      optionChoosen={record['sow_id']}
      callback={this.handleInputChange}
    />

    *** Defination of fields:
    record: Object {label:"Value to be Presented", dbName:"name in data object",ftype:"to have an object of format type"} 
    defaultValue: The value to be selected by default
    callback: "Event to occur on change of dropdown"
    ftype:"Particular field ftype Format type object
    {
      optionType:"Defines what type of Values are to be Populated['object'/'array']",
      optionMap: this Field is mandatory only when optionType=>object 
      {
        label: "Name of the field whose value should be printed as menu label"
        value: "Name of the Field whose value should be printed as manu value"
      }
    }
    "
 */

class LoadCheckList extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  handleChecklistChange = (e) => {
    var checkboxes = document.getElementsByName(e.target.name);
    var checkboxesChecked = [];
    // loop over them all
    for (var i = 0; i < checkboxes.length; i++) {
      // And stick the checked ones onto an array...
      if (checkboxes[i].checked) {
        checkboxesChecked.push(checkboxes[i].value);
      }
    }
    let nameArray = e.target.name.split('_');
    let elem = { target: { name: nameArray[0], value: checkboxesChecked.join('|') } };
    this.props.callback(elem);
  };
  render() {
    return (
      //
      <FormGroup id={`${this.props.record.dbname}_${this.props.itemid}`}>
        <div>
          {console.log(
            'LoadCheckList',
            this.props.optionChoosen,
            this.props.ftype.options[this.props.optionChoosen]
          )}
        </div>
        {this.props.ftype.options[this.props.optionChoosen]?.length > 0 &&
          this.props.ftype.options[this.props.optionChoosen].map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  name={`${this.props.record.dbname}_${this.props.itemid}`}
                  className={`${this.props.record.dbname}_${this.props.itemid}`}
                  value={option}
                  onChange={(e) => this.handleChecklistChange(e)}
                  color="primary"
                  checked={this.props.selectedValues.includes(option)}
                  disabled={this.props.disabled}
                />
              }
              label={option}
            />
          ))}
      </FormGroup>
    );
  }
}

export default LoadCheckList;
