import React, { Fragment } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Card, Checkbox, Grid, Typography, createTheme, ThemeProvider } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileView from '../../bids/components/fileView';
import CategoryTreeView from './categoryTreeView.jsx';
import Displayer from '../../../helpers/displayer.jsx';

// Create a custom theme with desired typography styles
const theme = createTheme({
  typography: {
    fontWeightBold: 900,
    body1: {
      color: 'blue'
    }
  }
});

const ListRow = ({ product, canView, callback }) => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment key={product.id}>
        <Grid
          container
          spacing={4}
          className="mt-4"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '30px'
          }}
        >
          <Grid item md={2}>
            {product.base_image ? (
              <FileView id={product.id} url={Displayer.absolutingImageURl(product.base_image)} />
            ) : (
              <FileView id={product.id} url="/assets/images/products/image.jpeg" />
            )}
          </Grid>

          <Grid item md={10}>
            <Grid container>
              <Grid item md={10}>
                <Typography variant="subtitle1" fontWeight="bold" className="text-muted">
                  {product.name}
                </Typography>
                <p>{product.sku}</p>
              </Grid>
              <Grid item md={2}>
                {canView ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => callback(product.id)}
                  >
                    Select
                  </Button>
                ) : (
                  <Grid>
                    <Checkbox
                      variant="contained"
                      color="primary"
                      onChange={(e) => callback(e.target.checked, product.id)}
                    />
                    <span>Select</span>
                  </Grid>
                )}
              </Grid>
              <Grid item md={12}>
                <Grid container>
                  <Grid item md={4}>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>Material:</span> {product.type}
                    </p>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>Color:</span> {product.color}
                    </p>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>Design:</span> {product.Design}
                    </p>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>Series:</span> {product.Series}
                    </p>
                  </Grid>
                  <Grid item md={4}>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>Length X Width X:</span> {product.type}
                    </p>
                    <Typography variant="subtitle1" fontWeight="bold" className="text-muted">
                      Price:
                    </Typography>
                    <Typography variant="body1">{product.price}</Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography variant="subtitle1" fontWeight="bold" className="text-muted">
                      Description:
                    </Typography>
                    <Typography variant="body1">{product.description}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <hr />
      </Fragment>
    </ThemeProvider>
  );
};

export default ListRow;
