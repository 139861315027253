import config from '../constants/index';
import Auth from './authorization';
import Postie from './postie';

const getAllBuildTypes = () => {
  let buildTypeJson = ['Society High Rise', 'Villa', 'Independent Floor', 'Commercial/ Corporate'];
  return buildTypeJson;
};

const getArenaTypeData = async ($filterable) => {
  var url = config.BASE_API_URL + 'arena/getTypes/' + $filterable + '/' + Auth.urlTokenizer();
  const TypesData = await Postie.fetchUrl(url);
  return TypesData;
};

const getArenaBrandName = async ($filterable) => {
  var url = config.BASE_API_URL + 'arena/brands' + $filterable + '/' + Auth.urlTokenizer();
  const BrandData = await Postie.fetchUrl(url);
  return BrandData;
};

const getVendorList = async () => {
  var url = config.BASE_API_URL + 'arena/vendors/' + Auth.urlTokenizer();
  let vendorlist = await Postie.fetchUrl(url);
  return vendorlist;
};

const getArenaCategories = async () => {
  let categories = {};
  var url = config.BASE_API_URL + 'arena/categories/' + Auth.urlTokenizer();
  categories = await Postie.fetchUrl(url);
  //console.log('categories Data', categories);
  return categories;
};

const getEavData = async (type) => {
  let data = [];
  var url = config.BASE_API_URL + 'arena/eav/' + type + '/' + Auth.urlTokenizer();
  data = await Postie.fetchUrl(url);
  //console.log('categories Data', categories);
  return data;
};

const getDesignPhasesJson = () => {
  let designPhasesJson = [
    'Fully Designed',
    'SD Budget',
    'DD Budget',
    'Design Assist',
    'Design Build'
  ];
  return designPhasesJson;
};

const getProjectTypeJson = () => {
  let designPhasesJson = [
    'Full Home Interior - New',
    'Full Home Interior - Renovation',
    'MODULAR WORKS - NEW',
    'MODULAR WORKS - Renovation',
    'PARTIAL INTERIORS/SPECIFIC AREA - NEW',
    'PARTIAL INTERIORS/SPECIFIC AREA - Renovation',
    'OFFICE/COMMERCIAL WORKS - NEW',
    'OFFICE/COMMERCIAL WORKS - Renovation'
  ];
  return designPhasesJson;
};

const getDivisonJson = () => {
  /*let divisonJson=[
		 'Turn Key','Consulting + Management', 'Consulting' 
	];*/
  let divisonJson = ['Design & build', 'Design', 'Execution', 'Labour only'];
  return divisonJson;
};

const getTypeofWork = () => {
  let divisonJson = ['Design & build', 'Design', 'Execution', 'Labour only'];
  return divisonJson;
};

const getleadJson = () => {
  let divisonJson = ['Nexus', 'livspace', 'homelane'];
  return divisonJson;
};

const getWorkableCities = () => {
  let Cities = [
    { id: '1', name: 'Noida' },
    { id: '2', name: 'Lucknow' }
  ];
  return Cities;
};
const getPossesions = () => {
  let divisonJson = ['In Process', 'With in a Month', 'Within 3 months', 'More Than 3 months'];
  return divisonJson;
};

const getArenaType = (type) => {
  let external_laminate = ['laminate', 'shutter_finish'];
  //console.log('getArenaType',type);
  if (external_laminate.includes(type.toLowerCase())) {
    type = 'external_laminates';
  }
  return type;
};

const getStages = () => {
  let stages = {
    PRESALES: {
      Master_Layout: 2,
      Design_Inspiration_Shared: 3,
      BOQ_shared: 4,
      Project_Rejected: 5,
      BOQ_approved: 6,
      site_measurement: 7,
      existing_elevation: 8
    },
    DIP: {
      scope_matrix_uploaded: 9,
      First_Renders_Shared: 11,
      Second_Renders_Shared: 12,
      Renders_Finalized: 13,
      BOQ_Shared: 14,
      On_hold: 18,
      Rejected: 19
    },
    PREEX: {
      Data_colation: 15,
      Kick_off: 17,
      final_upload: 20,
      PEC_Pending: 19,
      PEC_DL_Approval_Pending: 15,
      PEc_OM_Approval_Pending: 21
    },
    Exe: {
      Exe_Stage_1: 22,
      Exe_S1_POEC: 23,
      Stage_2_PEc_Pending: 24,
      Stage_2_PEc_DL_Approval_Pending: 25,
      Stage_2_PEc_OM_Approval_Pending: 26,
      Stage_2_Material_Ordering: 27,
      Exe_Stage_2: 28,
      'Exe_S2 POEC': 29,
      Furniture_Validation: 30,
      Raise_Order: 31,
      Exe_Stage_3: 32,
      Exe_S3_POEC: 33,
      Exe_Stage_4: 34,
      Exe_S4_POEC: 35,
      Exe_DL_Snags: 36,
      'Exe_DL_snags POEC': 37,
      Exe_OM_PHC: 38,
      Exe_Handover_with_snags: 39,
      Exe_Snags: 40
    },
    handover: { Handover: 41 }
  };
  return stages;
};

const getWorkScope = () => {
  let scopeJson = [
    'Electrical',
    'Flooring',
    'Layout Changes',
    'Washrooms',
    'Kitchen',
    'Furniture',
    'Wardrobe',
    'False ceiling',
    'Painting',
    'Doors & Windows'
  ];
  return scopeJson;
};

const getTagsJson = () => {
  let tagsJson = [
    'Backyard',
    'Balcony',
    'Bar unit',
    'Bathroom',
    'Bedrooms ',
    'Beds',
    'Cabinets',
    'Chairs',
    'Cladding',
    'Console Table',
    'Designer Mirrors',
    'Dining',
    'Dining table',
    'Display Unit',
    'Dressing table',
    'Dry pantry',
    'False Ceiling',
    'Foyer / Entrance',
    'Furniture',
    'Gallery',
    'Guest room',
    'Home Office/ Office',
    'Kids Room ',
    'Kids Study',
    'Kitchen ',
    'L-Shape ',
    'Light Effects',
    'Living Room',
    'Master Bedroom',
    'Mirror Panelling',
    'Paint Theme & Design',
    'Panelling',
    'Parallel',
    'Parents Room ',
    'Partitions',
    'Shelves',
    'Sofa',
    'Staircase',
    'Storage',
    'Straight',
    'Texture',
    'Tv Unit',
    'U-Shape ',
    'Understairs',
    'Walk-In-Closet',
    'Wall Design',
    'Wallpapers',
    'Wardrobe',
    'With Island'
  ];

  var url = config.BASE_API_URL + 'gallery/tags';
  return fetch(url)
    .then((res) => res.json())
    .then((json) => {
      json.data.forEach((tag) => {
        if (!tagsJson.includes(tag)) {
          tagsJson.push(tag);
        }
      });
      return tagsJson;
    });
};

const getModularDropdowns = (category, type) => {
  var url = config.BASE_API_URL + 'modular/dropdown/' + category + '/' + type;
  return fetch(url)
    .then((res) => res.json())
    .then((json) => {
      return json.data;
    });
};

const getNavDbdata = async () => {
  let url = config.BASE_API_URL + 'side/navigation/' + Auth.urlTokenizer();
  let data = await Postie.fetchUrl(url);
  //console.log('data', data);
  return data;
};
const getNavigationJson = async () => {
  const navigation = [
    { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
    { name: 'Lead Generator', path: '/lead_generator/dashboard', icon: 'dashboard' },
    { name: 'Redeem Requests', path: '/redeem/requests', icon: 'dashboard' },
    { name: 'AI', path: '/ai/renders', icon: 'dashboard' }
    //{ name: 'Arena Products', path: '/arena/productList', icon: 'A' }
  ];
  let data = await getNavDbdata();
  console.log('data', data);
  if (data && data.length > 0) {
    data.forEach((nav) => {
      navigation.push(nav);
    });
  }
  return navigation;
};

const getProjectRoles = () => {
  let tagsJson = [];

  var url = config.BASE_API_URL + 'configuration/getprojectroles';
  return fetch(url)
    .then((res) => res.json())
    .then((json) => {
      json.data.forEach((tag) => {
        tagsJson.push(tag);
      });
      return tagsJson;
    });
};

const getUnitTypes = (category) => {
  var url = config.BASE_API_URL + 'unit/type/' + category;
  return fetch(url)
    .then((res) => res.json())
    .then((json) => {
      return json.data;
    });
};

const getContractorList = () => {
  let contractors = [];
  var url = config.BASE_API_URL + 'team/contractors';
  return fetch(url)
    .then((res) => res.json())
    .then((json) => {
      json.data.forEach((contractor) => {
        if (!contractors.includes(contractor)) {
          contractors.push(contractor);
        }
      });
      return contractors;
    });
};
const getAutoCompleteData = (module) => {
  var url = config.BASE_API_URL + 'crud/autocomplete/' + module + '/' + Auth.urlTokenizer();
  return fetch(url)
    .then((res) => res.json())
    .then((json) => {
      return json.data;
    });
};

const getMaterialUnits = () => {
  let units = [];
  var url = config.BASE_API_URL + 'material/getUnits';
  return fetch(url)
    .then((res) => res.json())
    .then((json) => {
      json.data.forEach((unit) => {
        if (!units.includes(unit)) {
          units.push(unit);
        }
      });
      return units;
    });
};
const getMaterialData = () => {
  let data = [];
  var url = config.BASE_API_URL + 'material/getData';
  return fetch(url)
    .then((res) => res.json())
    .then((json) => {
      data = json.data;
      return data;
    });
};

const getPossibleOptions = async (crud, field, filters) => {
  const url = config.BASE_API_URL + 'possibleValues/' + crud.toLowerCase() + '/' + field;
  let finalFilters = {};
  if (Object.keys(filters).includes(field)) {
    Object.keys(filters).forEach((key) => {
      if (key !== field) {
        finalFilters[key] = filters[key];
      }
    });
  } else {
    finalFilters = filters;
  }
  const postData = {
    authToken: localStorage.getItem(config.Token_name),
    filters: finalFilters
  };
  const reply = await Postie.sendAsynchrnousCall(postData, url);
  if (reply.status === 'success') {
    if (reply.data) {
      return reply.data;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

const staticData = {
  getAllBuildTypes,
  getDesignPhasesJson,
  getDivisonJson,
  getTagsJson,
  getProjectTypeJson,
  getleadJson,
  getWorkScope,
  getMaterialUnits,
  getContractorList,
  getStages,
  getMaterialData,
  getWorkableCities,
  getTypeofWork,
  getPossesions,
  getArenaType,
  getUnitTypes,
  getProjectRoles,
  getModularDropdowns,
  getArenaTypeData,
  getArenaCategories,
  getVendorList,
  getArenaBrandName,
  getNavigationJson,
  getNavDbdata,
  getAutoCompleteData,
  getEavData,
  getPossibleOptions
};

export default staticData;
