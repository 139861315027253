import React, { Component } from 'react';
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox
} from '@mui/material';
import UploadFile from '../files/uploadFile';
import FileView from '../bids/components/fileView';
import displayer from 'app/helpers/displayer';
import AICaptioner from '../crudoperations/components/AICaptioner';
import AIReplacer from '../crudoperations/components/AIReplacer';
import { Fragment } from 'react';
import ConnectModule from '../crudoperations/connectModule';
import Authorization from 'app/helpers/authorization';
import staticData from 'app/helpers/staticdata';
import Postie from 'app/helpers/postie';
import config from '../../constants/index';
import Auth from 'app/helpers/authorization';
import ConnectGallery from '../crudoperations/connectGallery';
import GeneratorLeadRow from './GeneratorLeadRow';
import { CheckBox } from '@mui/icons-material';
import ReasonPopup from './ReasonPopup';

class LeadApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadList: '',
      projectType: '',
      openReasonPopup: false
    };
  }

  componentDidMount = async () => {
    const queryParams = displayer.getUrlParams('/lead/approval/');
    this.loadData(queryParams[0]);
    //this.getProjectTypeData();
  };
  loadData = async (leadId) => {
    const postData = {
      authtoken: Auth.getToken(),
      user_id: leadId
    };
    const url = config.BASE_API_URL + 'admin/getuserdetail';
    let jsonData = await Postie.sendAsynchrnousCall(postData, url);
    if (jsonData.status === 'success') {
      this.setState({ ...this.state, leadList: jsonData.data });
    }
  };

  handleOrganisationDetails = async (e) => {
    const { name, value } = e.target;
    console.log('handleOrganisationDetails', name, value);
    const postData = {
      token: this.state.leadList?.token,
      field: name,
      value
    };
    const url = config.BASE_API_URL + 'cms/auth/organization/update';
    const json = await Postie.sendAsynchrnousCall(postData, url);
    if (json.status === 'success') {
      const queryParams = displayer.getUrlParams('/lead/approval/');
      this.loadData(queryParams[0]);
    }
  };

  handleApproveLeads = async (e) => {
    const { name, checked } = e.target;
    console.log('handleApproveLeads', name, checked);
    if (checked) {
      const postData = {
        authtoken: Auth.getToken(),
        user_id: this.state.leadList?.id
      };
      const url = config.BASE_API_URL + 'admin/approveuser';
      const json = await Postie.sendAsynchrnousCall(postData, url);
      if (json.status === 'success') {
        const queryParams = displayer.getUrlParams('/lead/approval/');
        this.loadData(queryParams[0]);
      }
    } else {
      this.setState({ ...this.state, openReasonPopup: true });
    }
  };

  handleClosePiopup = () => {
    this.setState({ ...this.state, openReasonPopup: false });
  };
  render() {
    const { leadList, projectType } = this.state;
    console.log('projectType', projectType);

    return (
      <Fragment>
        <Grid container spacing={2} sx={{ margin: '10px', padding: '5px' }}>
          <Grid item xs={12} sm={12} md={12}>
            <h1>New Lead Approval</h1>
          </Grid>
        </Grid>
        <Grid style={{ margin: '22px', padding: '3px' }}>
          {leadList && <GeneratorLeadRow lead={leadList} showButtons={false} />}
        </Grid>
        <Grid style={{ margin: '22px', padding: '3px' }}>
          <h1>Lead Cost Configuration</h1>
        </Grid>
        <Grid container spacing={2} sx={{ margin: '10px', padding: '5px' }}>
          <Grid item xs={12} sm={4} md={4}>
            <h3>Lead Cost Type</h3>
            <label style={{ font: 'bold' }}>Fixed</label>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <h3>Platform Fee</h3>
            <TextField
              key={leadList?.organization?.fixed_lead_platform_fees}
              type="text"
              label="Fixed Lead Platform Fee"
              name="fixed_lead_platform_fees"
              defaultValue={leadList?.organization?.fixed_lead_platform_fees}
              onBlur={this.handleOrganisationDetails}
              style={{ width: '90%' }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <h3>Markup</h3>
            <TextField
              key={leadList?.organization?.fixed_lead_markup}
              type="text"
              label="Fixed Lead Markup"
              name="fixed_lead_markup"
              defaultValue={leadList?.organization?.fixed_lead_markup}
              onBlur={this.handleOrganisationDetails}
              style={{ width: '90%' }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ margin: '5px' }}>
          <Grid item xs={12} sm={4} md={4}>
            <label style={{ font: 'bold' }}>Conversion</label>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              key={leadList?.organization?.conversion_lead_platform_fee}
              type="text"
              label="Conversion Lead Platform Fee"
              name="conversion_lead_platform_fee"
              defaultValue={leadList?.organization?.conversion_lead_platform_fee}
              onBlur={this.handleOrganisationDetails}
              style={{ width: '90%' }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              key={leadList?.organization?.conversion_lead_markup}
              type="text"
              label="Conversion Lead Markup"
              name="conversion_lead_markup"
              defaultValue={leadList?.organization?.conversion_lead_markup}
              onBlur={this.handleOrganisationDetails}
              style={{ width: '90%' }}
            />
            <br />
            <Grid
              container
              alignItems="center"
              style={{ margin: '20px', padding: '4px', display: 'flex' }}
            >
              <Checkbox
                name="approve"
                style={{
                  color: 'blue',
                  marginRight: '8px'
                }}
                checked={leadList?.organization?.is_admin_verified === 1}
                onChange={this.handleApproveLeads}
              />
              <label style={{ fontWeight: 'bold', marginBottom: '0px' }}>Approve</label>
            </Grid>

            <br />
            <Button
              variant="contained"
              size="small"
              style={{ backgroundColor: '#2c3e50', color: '#fff', marginLeft: '8px' }}
              onClick={() => Postie.navigateLink(`/lead_generator/dashboard`)}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <ReasonPopup
          openPopup={this.state.openReasonPopup}
          closePopup={() => this.handleClosePiopup()}
          lead={leadList}
        />
      </Fragment>
    );
  }
}

export default LeadApproval;
