import React, { Component } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import FileView from 'app/views/bids/components/fileView';
import LoadTextField from './LoadTextField';
import {Grid} from '@mui/material';
import displayer from 'app/helpers/displayer';
import Authorization from 'app/helpers/authorization';
import config from "../../../constants/index";
import Postie from 'app/helpers/postie';
import { post } from 'jquery';

/*
<UploadFile 
  Filename={"logo"}
  displayTosection={false}
  areaName={""}
  type={"logo"}
  projectId={0}
  areaId={0}

  />

 */

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  '& .closeButton': {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className="closeButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  '&.root': { padding: theme.spacing(2) }
}));

class AICaptioner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading:false,
      outputGenerated:(this.props.defaultValue?.length>0),
      outputText:this.props.defaultValue,
      outputImage:"",
      inputText:this.props.prompt,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleInputChange=(e)=>{
    const{name,value}=e.target;
    this.setState({
        ...this.state,
        [name]:value,
    })
  }
  handleGeneratePrompt=async()=>{
    this.setState({loading:true});
    let postData={
        authToken:localStorage.getItem(config.Token_name),
        url:this.props.image,
        prompt:this.state.inputText,
    };
    let serviceUrl=config.BASE_API_URL+'arena/ai/caption';
    let response=await Postie.sendAsynchrnousCall(postData,serviceUrl);
    if(response.status==="success"){
        this.setState({...this.state,outputText:response.data,outputGenerated:true});
    }else{
        Postie.showAlert(response);
    }
    this.setState({loading:false});
  }
  handleGenerateImage=async()=>{
    this.setState({loading:true,outputImage:""});
    let postData={
        authToken:localStorage.getItem(config.Token_name),
        prompt:this.state.outputText,
    };
    let serviceUrl=config.BASE_API_URL+'arena/ai/text2img';
    let response=await Postie.sendAsynchrnousCall(postData,serviceUrl);
    if(response.status==="success"){
        this.setState({...this.state,outputImage:response.data});
    }else{
        Postie.showAlert(response);
    }
    this.setState({loading:false});
  }
  approveDescription=()=>{
    this.props.callback(this.state.outputText);
    this.handleClose();
  }

  handleFileUpload = (file) => {
    let response = JSON.parse(file.xhr.response);
    if (response.status === 'success') {
      console.log('fileUploaded', response);
      this.props.callback(response);
    }
    //Postie.showAlert(response);
    this.handleClose();
  };
  render() {
    return (
      <div>
        <p>{this.props.defaultValue}</p>
        <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
          Generate {this.props.placeholder}
        </Button>

        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth="lg" // Set the maxWidth to control the width of the dialog box
          fullWidth // This ensures that the dialog takes up the full width
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Generate  {this.props.placeholder}
          </DialogTitle>
          <DialogContent dividers>
          <Grid container spacing={2} >
            <Grid item md="4" xs="6" style={{background:'#dddddd',padding:'5px 10px'}}>
                <FileView  
                    id={`file-view`}
                    url={displayer.absolutingImageURl(this.props.image)}
                    style={{width:'90%',maxheight:'300px'}}/>

                <LoadTextField
                    record={{'label':""}}
                    name={'inputText'}
                    style={{ width: '90%' }}
                    type={'text'}
                    placeHolder={`Prompt to describe the image`}
                    multiline={true}
                    defaultValue={this.state.inputText}
                    callback={(event) => this.handleInputChange(event)}
                />
                <Button  style={{ margin: '10px' }} variant="contained"color="primary" onClick={()=>this.handleGeneratePrompt()}>Generate Prompt</Button>
            </Grid>
            <Grid item md="8" xs="6"> 
              {this.state.outputGenerated && <LoadTextField
                    record={{'label':""}}
                    name={'outputText'}
                    style={{ width: '90%' }}
                    type={'text'}
                    placeHolder={`Image Caption`}
                    multiline={true}
                    defaultValue={this.state.outputText}
                    callback={(event) => this.handleInputChange(event)}
                />}

                {this.state.outputGenerated  && <Button  style={{ margin: '10px' }} variant="contained"color="primary" onClick={()=>this.handleGenerateImage()}>Generate Image</Button>}

                {this.state.outputImage?.length>0  && <Button  style={{ margin: '10px' }} variant="contained"color="success" onClick={()=>this.approveDescription()}>Approve Description</Button>}

                {this.state.loading && <h3 className='text-primary'>Loading ...</h3>}
                {this.state.outputImage.length>0 &&<FileView  
                    id={`outputImage`}
                    url={displayer.absolutingImageURl(this.state.outputImage)}
                    style={{width:'90%',maxheight:'300px'}}/>}
            </Grid>
            
          </Grid>

          </DialogContent>
                   
        </Dialog>
      </div>
    );
  }
}

export default AICaptioner;
