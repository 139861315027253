import React from 'react';
import { Autocomplete, Grid, TextField,FormControl,Button} from '@mui/material';
import Postie from 'app/helpers/postie';
import config from '../../../constants/index.jsx';
import { type } from 'jquery';

/*
  # How to Call this function
  <Tags
      options={categories}
      label={`Product Categories`}
      placeholder={`Select / Add  Product Categories`}
      baseModel={'product_categories'}
      defaultValue={
        this.props.product?.product_categories
          ? JSON.parse(this.props.product?.product_categories)
          : []
      }
      handleChange={(data) => {
        let elem = { target: { name: 'product_categories', value: data } };
        this.props.handleInputChange(elem);
      }}
    />

    *** Defination  of Parameter:

    options: "This would consist of array o0f object with id  and name field mandataory"
    label: "Label to be displayed to user";
    placeholder: "Place holder to be displatyed to user"
    baseModel: "Name of the Model to which the new value is to be saved"
    defaultvalue: It takes array of object identivcal to options
    handleChange : "This function is called on Blur of the object to save the values to respective module return array of objects identical to options od the selected values
    "

 */

export default class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.defaultValue
    };
  }
  componentDidMount = () => {
    console.log('defaulttags', this.state.tags);
  };

  onTagsChange = async (newValue) => {
    console.log('ontagchanged', newValue,typeof(newValue));
    if(typeof(newValue)!=="object"){
      newValue = await this.submitData(newValue);
      console.log('created new Item', newValue);
    }
    let tags=this.state.tags;
    tags[newValue.name]="";
    this.setState({...this.state,tags:tags},function(){console.log('updated tags',this.state)});
    this.props.handleChange({target:{name:newValue.name,value:""}});    
  };

  submitData = async (name) => {
    console.log('Submit Data:', name);
    const model = this.props.baseModel;
    const authToken = localStorage.getItem(config.Token_name);
    const postData = {
      authToken,
      name: name
    };
    console.log('update product', postData);
    let serviceUrl = config.BASE_API_URL + 'crud/' + model + '/addUpdate';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status !== 'success') {
      Postie.showAlert(reply);
    } else {
      return reply.data;
    }
  };

  saveTags(data) {
    let stateTags = this.state.tags;
    let counter = 0;
    stateTags.forEach((tag) => {
      if (tag.id === data.id) {
        counter++;
      }
    });
    if (counter === 0) {
      stateTags.push(data);
      this.setState({ tags: stateTags });
    }
  }
  removeTag(data) {
    let stateTags = this.state.tags;
    let newStateTags = [];
    stateTags.forEach((tag) => {
      if (tag.id !== data.id) {
        newStateTags.push(tag);
      }
    });
    this.setState({ tags: newStateTags });
  }

  isObject = (value) => {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  };

  updateTags(e) {
    const {name,value}=e.target.name;
  }
  removeIndex(index) {
    console.log('removing', index, this.state.tags[index]);
  }

  render() {
    console.log('autcomplete',this.props.options,this.state.tags);
    const fixedFields=['length','width','thickness'];
    return (
      <div style={this.props.style}>        
        <Autocomplete          
          id={'tags'}
          key={this.state.tags}
          options={this.props?.options}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          //defaultValue={this.state.tags}
          freeSolo
          onChange={(event, newValue) => {
            this.onTagsChange(newValue);
          }}
          // onBlur={() => {
          //   this.props.handleChange(this.state.tags);
          // }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={this.props?.label}
              placeholder={this.props?.placeholder}
              margin="normal"
              fullWidth
            />
          )}
        />
        <Grid key={this.state.tags}>      
          {this.state.tags && Object.keys(this.state.tags).map((tag)=>(
            <>
            {fixedFields.includes(tag)?null: 
            <Grid item xs={12} md={12} key={tag}>
              <FormControl >
                <TextField
                  type="text"
                  name={tag}
                  id={tag}
                  defaultValue={this.state.tags[tag]}
                  onChange={(e)=>this.props.handleChange(e)}
                  
                  // errorMessages={['this field is required']}
                  label={tag}
                  validators={['required', 'minStringLength: 4']}
                />                
              </FormControl>
              <Button onClick={()=>this.props.handleRemove(tag)}>Remove</Button>
            </Grid>}
            </>

          ))}
          
        </Grid>
      </div>
    );
  }
}
