import React, { Component } from 'react';
import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Fragment } from 'react';
import Postie from 'app/helpers/postie';
import config from '../../constants/index';
import Auth from 'app/helpers/authorization';
import ReedemRow from './ReedemRow';
import ApproveLeadPopup from './ApproveLeadPopup';

class RedeemRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadList: '',
      filter: '',
      projectType: '',
      openReasonPopup: false,
      type: ''
    };
  }

  componentDidMount = async () => {
    this.loadData({ approval: 'Pending' });
    //this.getProjectTypeData();
  };
  loadData = async (filter = '') => {
    const postData = {
      authtoken: Auth.getToken(),
      role: 'Lead Generator',
      filters: filter
    };
    const url = config.BASE_API_URL + 'admin/getusers';
    let jsonData = await Postie.sendAsynchrnousCall(postData, url);
    if (jsonData.status === 'success') {
      this.setState({ ...this.state, leadList: jsonData.data });
    }
  };
  handleApplyFilter = (e) => {
    const { name, value } = e.target;
    console.log('handleApplyFilter', name, value);
    this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          [name]: value
        }
      }),
      () => {
        this.loadData(this.state.filter);
      }
    );
  };

  handleApprove = (type) => {
    this.setState({ ...this.state, openReasonPopup: true, type: type });
  };

  handleClosePopup = () => {
    this.setState({ ...this.state, openReasonPopup: false, type: '' });
  };

  render() {
    const { leadList, projectType } = this.state;
    console.log('projectType', projectType);

    return (
      <Fragment>
        <Grid container spacing={2} sx={{ margin: '10px', padding: '5px' }}>
          <Grid item xs={12} sm={12} md={12}>
            <h1>Redemption Requests</h1>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <label style={{ font: 'bold' }}>Search By User Name</label>
            <TextField
              type="text"
              label="Search By User Name"
              name="name"
              onChange={this.handleApplyFilter}
              style={{ width: '90%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Button
              onClick={() => this.handleApprove('approve')}
              color="primary"
              variant="contained"
              style={{ float: 'right', margin: '2px' }}
            >
              Approve Selected
            </Button>
            <Button
              onClick={() => this.handleApprove('reject')}
              color="error"
              variant="contained"
              style={{ float: 'right', margin: '2px' }}
            >
              Reject Selected
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ margin: '35px', padding: '3px' }}>
          {leadList &&
            leadList.map((lead, index) => (
              <Grid item xs={12} sm={6} md={3} key={index} style={{ margin: '20px' }}>
                <ReedemRow lead={lead} showButtons={true} />
              </Grid>
            ))}
        </Grid>
        <ApproveLeadPopup
          openPopup={this.state.openReasonPopup}
          closePopup={() => this.handleClosePopup()}
          lead={''}
          type={this.state.type}
        />
      </Fragment>
    );
  }
}

export default RedeemRequests;
