import React, { Component } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';
import UploadFile from '../files/uploadFile';
import FileView from '../bids/components/fileView';
import displayer from 'app/helpers/displayer';
import { Fragment } from 'react';
import Postie from 'app/helpers/postie';
import ApproveLeadPopup from './ApproveLeadPopup';

class ReedemRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openReasonPopup: false,
      type: ''
    };
  }

  handleApprove = (type) => {
    this.setState({ ...this.state, openReasonPopup: true, type: type });
  };

  handleClosePopup = () => {
    this.setState({ ...this.state, openReasonPopup: false, type: '' });
  };

  render() {
    const { lead, showButtons } = this.props;

    return (
      <Fragment>
        <Grid
          container
          spacing={2}
          style={{
            borderRadius: '5px',
            border: '1px solid',
            padding: '16px', // Added padding to ensure the content doesn't touch the border
            margin: '16px' // Added margin to give space outside the container
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5}>
              <h3>Request Id: 12345</h3>
            </Grid>
            <Grid item xs={12} sm={6} md={7} style={{ display: 'flex' }}>
              <Button
                onClick={() => this.handleApprove('approve')}
                color="primary"
                variant="contained"
              >
                Approve
              </Button>
              <Button
                onClick={() => this.handleApprove('reject')}
                color="error"
                variant="contained"
              >
                Reject
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <p>
                <strong>User:</strong> John Smith
              </p>
              <p>
                <strong>Date:</strong> 01-8-2024
              </p>
              <p>
                <strong>Status:</strong> Pending
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'right' }}>
              <h3>₹ 3000.00</h3>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <label>
                <strong>Payment Details</strong>
              </label>
              <p>
                <strong>Bank Name:</strong> ABC Bank
              </p>
              <p>
                <strong>IFSC:</strong> ABC00000084
              </p>
              <p>
                <strong>Bank Address:</strong> Goa
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <label>
                <strong>Transaction Notes</strong>
              </label>
              {/* Add any transaction notes here */}
            </Grid>
          </Grid>
        </Grid>
        <ApproveLeadPopup
          openPopup={this.state.openReasonPopup}
          closePopup={() => this.handleClosePopup()}
          lead={''}
          type={this.state.type}
        />
      </Fragment>
    );
  }
}

export default ReedemRow;
