import React, { Component } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Chip } from '@mui/material';
import displayer from 'app/helpers/displayer';
/*
<FileView
  id={`file-${item.id}`}
  url={displayer.absolutingImageURl(item[record['dbname']])}
/>
 */
class FileView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  OpenFile(url) {
    var win = window.open(url, '_blank');
    if (win) {
      //Browser has allowed it to be opened
      win.focus();
    } else {
      //Browser has blocked it
      alert('Please allow popups for this website');
    }
  }
  fileType(url) {
    if (
      url.toLowerCase().indexOf('.jpg') > -1 ||
      url.toLowerCase().indexOf('.png') > -1 ||
      url.toLowerCase().indexOf('.jpeg') > -1 ||
      url.toLowerCase().indexOf('.webp') > -1 ||
      url.toLowerCase().indexOf('.jfif') > -1
    ) {
      return 'image';
    } else {
      let file = url.split('.');
      return file[file.length - 1];
    }
  }
  render() {
    return (
      <div className="img-thumbnail" onClick={() => this.OpenFile(this.props.url)}>
        {this.fileType(this.props.url) === 'image' ? (
          <img src={this.props.url} style={{ width: '100%' }} alt={this.props.url} />
        ) : (
          <Chip
            label={this.fileType(this.props.url).toUpperCase()}
            icon={<AttachFileIcon />}
            size="medium"
            color="primary"
            sx={{ margin: '5px' }}
          ></Chip>
        )}
      </div>
    );
  }
}
export default FileView;
