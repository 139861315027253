import React, { Component } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  createTheme,
  Select,
  Checkbox,
  ListItemText,
  Autocomplete
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Postie from '../../helpers/postie.jsx';
import config from '../../constants/index.jsx';
import displayer from 'app/helpers/displayer';
import Auth from 'app/helpers/authorization';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { CopyAll, Delete, Edit, Remove, RoundedCorner, ViewAgenda } from '@mui/icons-material';
import isToday from 'date-fns/isToday/index.js';
import staticData from 'app/helpers/staticdata.jsx';
import swal from 'sweetalert';
import { post } from 'jquery';
import Authorization from 'app/helpers/authorization';
import ListRow from '../arena/Components/listRow.jsx';

class AddRulePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleValue: '',
      selectedDays: [],
      selectedLocations: []
    };
  }
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      ruleValue: value
    });
  };

  handleAddTime = (e) => {
    const { name, value } = e.target;
    const { ruleValue } = this.state;

    // Temporarily store the startTime and endTime
    const updatedTimes = {
      ...ruleValue,
      [name]: value
    };

    // Check if both startTime and endTime are set
    const startTime = updatedTimes.startTime || '';
    const endTime = updatedTimes.endTime || '';

    // Set the state only when both times are available
    if (startTime && endTime) {
      this.setState({
        ruleValue: `${startTime}-${endTime}`
      });
    } else {
      // Update only the necessary part without setting the full time range yet
      this.setState({
        ruleValue: updatedTimes
      });
    }
  };

  handleDayChange = (event) => {
    const { value } = event.target;
    this.setState({ selectedDays: value });
    this.setState({
      ruleValue: value.toString()
    });
  };

  // handleLocationChange = (event) => {
  //   const { value } = event.target;
  //   this.setState({ selectedlocations: value });
  //   this.setState({
  //     ruleValue: value.toString()
  //   });
  // };

  handleLocationChange = (event, newValue) => {
    const { name, value, checked } = event.target;
    console.log('handleLocationChange', name, value, checked);
    this.setState({ selectedLocations: newValue });
    // this.setState({
    //   ruleValue: newValue.toString()
    // });
    this.setState((prevState) => {
      let updatedValues = prevState.ruleValue[name] || [];

      if (checked) {
        // Add the value to the array if checked is true and it's not already in the array
        updatedValues = [...updatedValues, value];
      } else {
        // Remove the value from the array if checked is false
        updatedValues = updatedValues.filter((item) => item !== value);
      }

      return {
        ruleValue: {
          ...prevState.ruleValue,
          [name]: updatedValues
        }
      };
    });
  };

  handleProductSelect = async (checked, e) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      let updatedValues = prevState.ruleValue[name] || [];

      if (checked) {
        // Add the value to the array if checked is true and it's not already in the array
        updatedValues = [...updatedValues, value];
      } else {
        // Remove the value from the array if checked is false
        updatedValues = updatedValues.filter((item) => item !== value);
      }

      return {
        ruleValue: {
          ...prevState.ruleValue,
          [name]: updatedValues
        }
      };
    });
  };

  submitSelectedProduct = async () => {
    let combineData = this.state.ruleValue;

    // Create an array of promises
    let promises = Object.keys(combineData).flatMap((dataKey) => {
      // Ensure combineData[dataKey] is an array
      let dataArray = Array.isArray(combineData[dataKey])
        ? combineData[dataKey]
        : combineData[dataKey].split(',');

      // Map each value to a promise
      return dataArray.map((value) => {
        return this.props.submitCombineData(dataKey, value.toString().trim());
      });
    });

    try {
      await Promise.all(promises);
      console.log('All products submitted successfully.');
      this.props.closeRulePopup();
    } catch (error) {
      console.error('Error submitting products:', error);
    }
  };

  handlecategorySelect = async (e) => {
    console.log('handlecategorySelect', e.target);
    const { name, value } = e.target;
    console.log('handlecategorySelect', name, value);
    if (value === 'reset') {
      this.setState(
        (prevState) => {
          const updatedRuleValue = { ...prevState.ruleValue };
          delete updatedRuleValue[name]; // Remove the value for the specific key
          return {
            ruleValue: updatedRuleValue
          };
        },
        function () {
          console.log('check value', this.state.ruleValue);
          this.props.getProductData(this.state.ruleValue);
          this.props.getCategoryData();
        }
      );
      this.props.getCategoryData();
    } else {
      this.setState(
        {
          ruleValue: { ...this.state.ruleValue, [name]: value }
        },
        function () {
          console.log('check value', this.state.ruleValue);
          this.props.getProductData(this.state.ruleValue);
          this.props.getCategoryData(value);
        }
      );
    }
  };

  handleAddCombineData = async () => {
    let combineData = this.state.ruleValue;
    let promises = Object.keys(combineData).map((data) => {
      return this.props.submitCombineData([data].toString(), combineData[data].toString());
    });

    try {
      await Promise.all(promises);
      console.log('All data submitted successfully.');
      this.props.closeRulePopup();
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  submitLocationData = async () => {
    let combineData = this.state.selectedLocations;
    let promises = Object.keys(combineData).map((data) => {
      return this.props.submitCombineData('location', combineData[data].toString());
    });

    try {
      await Promise.all(promises);
      console.log('All data submitted successfully.');
      this.props.closeRulePopup();
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  render() {
    const { ruleType, updateCoupen, categories, productCategories, productData, locations } =
      this.props;
    const daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ];
    const { selectedDays, selectedLocations, ruleValue } = this.state;
    console.log('selectedLocations', selectedLocations, ruleValue);
    return (
      <Dialog
        open={this.props.openPopup}
        //onClose={this.toggleDialog}
        fullWidth
        maxWidth="lg"
        disableBackdropClick
      >
        <DialogTitle>Add Rule For {ruleType}</DialogTitle>
        <DialogContent>
          {['min_purchase', 'min_product'].includes(ruleType) && (
            <TextField
              type="number"
              margin="dense"
              label="Enter Rule Value"
              name={ruleType}
              onChange={(e) => this.handleInputChange(e)}
              fullWidth
            />
          )}
          {['order_time'].includes(ruleType) && (
            <Grid container spacing={2}>
              <Grid item md={6}>
                <strong>Start Time</strong>
                <input
                  aria-label="Time"
                  type="time"
                  margin="dense"
                  name="startTime"
                  onChange={(e) => this.handleAddTime(e)}
                />
              </Grid>
              <Grid item md={6}>
                <strong>End Time</strong>
                <input
                  aria-label="Time"
                  type="time"
                  margin="dense"
                  name="endTime"
                  onChange={(e) => this.handleAddTime(e)}
                />
              </Grid>
            </Grid>
          )}
          {['order_day'].includes(ruleType) && (
            <Grid fullWidth>
              <Grid>
                <FormControl fullWidth>
                  <InputLabel id="weekdays-label">Select Weekdays</InputLabel>
                  <Select
                    fullWidth
                    labelId="weekdays-label"
                    id="weekdays-select"
                    multiple
                    value={selectedDays}
                    onChange={this.handleDayChange}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {daysOfWeek.map((day) => (
                      <MenuItem key={day} value={day}>
                        <Checkbox checked={selectedDays.indexOf(day) > -1} />
                        <ListItemText primary={day} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
          {/* {['location'].includes(ruleType) && (
            <Grid fullWidth>
              <Grid>
                <FormControl fullWidth>
                  <InputLabel id="weekdays-label">Select Locations</InputLabel>
                  <Select
                    fullWidth
                    labelId="location-label"
                    id="location-select"
                    multiple
                    value={selectedlocations}
                    onChange={this.handleLocationChange}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {locations.map((location) => (
                      <MenuItem key={location} value={location}>
                        <Checkbox checked={selectedlocations.indexOf(location) > -1} />
                        <ListItemText primary={location} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )} */}
          {['location'].includes(ruleType) && (
            <Grid container>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    options={locations}
                    value={selectedLocations}
                    onChange={this.handleLocationChange}
                    name="location"
                    filterSelectedOptions
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <Box component="li" {...props}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Locations" variant="outlined" />
                    )}
                    renderTags={(selected, getTagProps) =>
                      selected.map((option, index) => (
                        <ListItemText key={index} primary={option} {...getTagProps({ index })} />
                      ))
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
          {['workCategory', 'productCategory', 'product'].includes(ruleType) && (
            <Grid fullWidth>
              <Grid style={{ marginTop: '7px' }}>
                <FormControl fullWidth>
                  <InputLabel id="weekdays-label">Select Category</InputLabel>
                  <Select
                    key={this.state.ruleValue.WorkCategory}
                    fullWidth
                    labelId="weekdays-label"
                    id="weekdays-select"
                    defaultValue={this.state.ruleValue.WorkCategory}
                    name="WorkCategory"
                    onChange={(e) => this.handlecategorySelect(e)}
                  >
                    <MenuItem color="warning" value="reset">
                      Reset Values
                    </MenuItem>
                    {categories &&
                      categories.map((category, index) => (
                        <MenuItem key={index} value={category.sow}>
                          {category.sow}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid style={{ marginTop: '7px' }}>
                <FormControl fullWidth>
                  <InputLabel id="weekdays-label">Select Product Category</InputLabel>
                  <Select
                    key={this.state.ruleValue.ProductCategory}
                    fullWidth
                    labelId="weekdays-label"
                    id="weekdays-select"
                    defaultValue={this.state.ruleValue.ProductCategory}
                    name="ProductCategory"
                    onChange={(e) => this.handlecategorySelect(e)}
                  >
                    <MenuItem color="warning" value="reset">
                      Reset Values
                    </MenuItem>
                    {productCategories?.categories?.data?.map((category, index) => (
                      <MenuItem key={index} value={category.name}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid style={{ marginTop: '7px' }}>
                <FormControl fullWidth>
                  <InputLabel id="weekdays-label">Select Brand</InputLabel>
                  <Select
                    key={this.state.ruleValue.Brand}
                    fullWidth
                    labelId="weekdays-label"
                    id="weekdays-select"
                    value={this.state.ruleValue.Brand}
                    name="Brand"
                    onChange={(e) => this.handlecategorySelect(e)}
                  >
                    <MenuItem color="warning" value="reset">
                      Reset Values
                    </MenuItem>
                    {productCategories?.brands?.data?.map((brand, index) => (
                      <MenuItem key={index} value={brand.brand_name}>
                        {brand.brand_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid style={{ marginTop: '7px' }}>
                <FormControl fullWidth>
                  <InputLabel id="weekdays-label">Select Series</InputLabel>
                  <Select
                    key={this.state.ruleValue.series}
                    fullWidth
                    labelId="weekdays-label"
                    id="weekdays-select"
                    value={this.state.ruleValue.series}
                    name="series"
                    onChange={(e) => this.handlecategorySelect(e)}
                  >
                    <MenuItem color="warning" value="reset">
                      Reset Values
                    </MenuItem>
                    {productCategories?.series?.data?.map((series, index) => (
                      <MenuItem key={index} value={series.series}>
                        {series.series}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
          {productData && productData?.records?.length > 0 && ruleType === 'product' && (
            <Grid fullWidth>
              {productData?.records?.map((product, index) => (
                <ListRow
                  key={product}
                  product={product}
                  index={index}
                  canView={false}
                  callback={(checked, id) =>
                    this.handleProductSelect(checked, {
                      target: { name: 'product', value: id }
                    })
                  }
                />
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closePopup} color="secondary">
            Cancel
          </Button>
          {['product'].includes(ruleType) ? (
            <Button onClick={() => this.submitSelectedProduct()} color="primary">
              Add
            </Button>
          ) : ['location'].includes(ruleType) ? (
            <Button onClick={() => this.submitLocationData()} color="primary">
              Add
            </Button>
          ) : ['workCategory', 'productCategory'].includes(ruleType) ? (
            <Button onClick={() => this.handleAddCombineData()} color="primary">
              Add
            </Button>
          ) : (
            <Button onClick={() => this.props.onSubmitRule(this.state.ruleValue)} color="primary">
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default AddRulePopup;
