import React, { Component } from 'react';
import { Button, TextField, Grid, Select, FormControl, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CategoryTreeView from './categoryTreeView';
import staticData from 'app/helpers/staticdata';
import config from '../../../constants/index.jsx';
import Postie from '../../../helpers/postie';
import Auth from '../../../helpers/authorization';
import displayer from 'app/helpers/displayer';

class AddVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [], // Data to store API response
      editedData: [] // Data to store edited values
    };
  }

  componentDidMount() {
    this.loadData();
  }
  loadData = async () => {
    // let editedData = await this.loadConnectedVendors(this.props.productId);
    // let data = await staticData.getVendorList();
    // this.setState({ data, editedData }, function () {
    //   console.log('vendor loaded', this.state);
    // });
    let editedData = await this.loadConnectedVendors(this.props.productId);
    let stores = [];
    editedData.forEach((mappedRecord) => {
      stores.push(mappedRecord.store_id);
    });
    let data = await staticData.getVendorList();
    data.forEach((record) => {
      record['alreadyMapped'] = stores.includes(record.id);
    });

    this.setState({ data, editedData }, function () {
      console.log('vendor loaded', this.state, stores);
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  loadConnectedVendors = async (id) => {
    const Url = config.BASE_API_URL + 'arena/connected/vendor/' + id + '/' + Auth.urlTokenizer();
    //console.log(Url);
    let jsonData = Postie.fetchUrl(Url);
    return jsonData;
  };

  handleFieldChange = async (event, mapId) => {
    const { name, value } = event.target;
    if (value.length > 0) {
      let servicesUrl = config.BASE_API_URL + 'arena/updatevendormapping';
      let postData = {
        authToken: localStorage.getItem(config.Token_name),
        field: name,
        map_id: mapId,
        value: value
      };
      let reply = await Postie.sendAsynchrnousCall(postData, servicesUrl);
      if (reply.status === 'success') {
        this.loadData();
      } else {
        Postie.showAlert(reply);
      }
    }
  };
  addVendorMap = async (event, storeId, productId) => {
    let servicesUrl = config.BASE_API_URL + 'arena/addvendormapping';
    let postData = {
      authToken: localStorage.getItem(config.Token_name),
      store_id: storeId,
      product_id: productId
    };
    let reply = await Postie.sendAsynchrnousCall(postData, servicesUrl);
    if (reply.status === 'success') {
      event.target.remove();
      this.loadData();
    } else {
      Postie.showAlert(reply);
    }
  };

  render() {
    const { data, editedData } = this.state;
    const addedVendorIds = editedData.map((item) => item.store?.vendor?.id);
    console.log('previous vendors', addedVendorIds);

    return (
      <>
        <h1
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: '20px'
          }}
        >
          {' '}
          Add Vendor
        </h1>
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleOpen}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: '20px'
          }}
        >
          Assign Vendor to Product
        </Button>
        <Table
          style={{
            marginLeft: '20px'
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Vendor</TableCell>
              <TableCell>Store</TableCell>
              <TableCell>Product code</TableCell>
              <TableCell>MRP</TableCell>
              <TableCell>NLC</TableCell>
              <TableCell>MOU</TableCell>
              <TableCell>ETD (In days)</TableCell>
              <TableCell>Gst %</TableCell>
              <TableCell>Gst Included</TableCell>
              <TableCell>Mark up%</TableCell>
              <TableCell>Last Updated Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {editedData &&
              editedData.length > 0 &&
              editedData.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <p>{item.store?.vendor?.vendor_name}</p>
                  </TableCell>
                  <TableCell>{item.store.store_name}</TableCell>
                  <TableCell>
                    <TextField
                      name="product_code"
                      type="text"
                      defaultValue={item.product_code}
                      onChange={(event) => this.handleFieldChange(event, item.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="price"
                      type="number"
                      defaultValue={item.price}
                      onChange={(event) => this.handleFieldChange(event, item.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="nlc"
                      type="number"
                      defaultValue={item.nlc}
                      onChange={(event) => this.handleFieldChange(event, item.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="mou"
                      type="number"
                      defaultValue={item.mou}
                      onChange={(event) => this.handleFieldChange(event, item.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="mdt"
                      type="number"
                      defaultValue={item.mdt}
                      onChange={(event) => this.handleFieldChange(event, item.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl variant="outlined" sx={{ m: 1, width: '90%' }}>
                      <Select
                        name="gst"
                        type="number"
                        defaultValue={item.gst}
                        onChange={(event) => this.handleFieldChange(event, item.id)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="5">
                          <em>5</em>
                        </MenuItem>
                        <MenuItem value="12">
                          <em>12</em>
                        </MenuItem>
                        <MenuItem value="18">
                          <em>18</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl variant="outlined" sx={{ m: 1, width: '90%' }}>
                      <Select
                        name="gst_included"
                        type="number"
                        defaultValue={item.gst_included}
                        onChange={(event) => this.handleFieldChange(event, item.id)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Yes">
                          <em>Yes</em>
                        </MenuItem>
                        <MenuItem value="No">
                          <em>No</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="markup"
                      type="number"
                      defaultValue={item.markup}
                      onChange={(event) => this.handleFieldChange(event, item.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <p>{displayer.dateFormater(item.updated_at)}</p>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth="md">
          <DialogTitle>Add Vendor to Product</DialogTitle>
          <DialogContent>
            {data &&
              data.map((vendor) => (
                <Grid container spacing={2}>
                  <Grid item md={8} xs={8}>
                    <h4>{vendor.vendor.vendor_name}</h4>
                    <p>{vendor.store_address}</p>
                  </Grid>
                  <Grid item md={4} xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => this.addVendorMap(e, vendor.id, this.props.productId)}
                      disabled={vendor.alreadyMapped} // Disable the button if vendor is already added
                    >
                      {vendor.alreadyMapped ? (
                        <>Already Mapped</>
                      ) : (
                        <>
                          <AddIcon />
                          Add {vendor.vendor.vendor_name}
                        </>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              ))}
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default AddVendor;
