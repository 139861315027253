import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

/*
  # How to Call this function
  <Tags
      options={categories}
      label={`Product Categories`}
      placeholder={`Select / Add  Product Categories`}
      baseModel={'product_categories'}
      defaultValue={
        this.props.product?.product_categories
          ? JSON.parse(this.props.product?.product_categories)
          : []
      }
      handleChange={(data) => {
        let elem = { target: { name: 'product_categories', value: data } };
        this.props.handleInputChange(elem);
      }}
    />

    *** Defination  of Parameter:

    options: "This would consist of array o0f object with id  and name field mandataory"
    label: "Label to be displayed to user";
    placeholder: "Place holder to be displatyed to user"
    baseModel: "Name of the Model to which the new value is to be saved"
    defaultvalue: It takes array of object identivcal to options
    handleChange : "This function is called on Blur of the object to save the values to respective module return array of objects identical to options od the selected values
    "

 */

export default class SingleTonTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.defaultValue
    };
  }

  onTagsChange = async (newValue) => {
    console.log('ontagchanged', newValue);
    this.props.handleChange(newValue);
  };

  saveTags(data) {
    let stateTags = this.state.tags;
    let counter = 0;
    stateTags.forEach((tag) => {
      if (tag.id === data.id) {
        counter++;
      }
    });
    if (counter === 0) {
      stateTags.push(data);
      this.setState({ tags: stateTags });
    }
  }
  removeTag(data) {
    let stateTags = this.state.tags;
    let newStateTags = [];
    stateTags.forEach((tag) => {
      if (tag.id !== data.id) {
        newStateTags.push(tag);
      }
    });
    this.setState({ tags: newStateTags });
  }

  isObject = (value) => {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  };

  updateTags(value) {
    if (!this.isObject(value)) {
      let opt = { id: 0, name: value };
      value = opt;
    }
    return value;
  }
  removeIndex(index) {
    console.log('removing', index, this.state.tags[index]);
  }

  render() {
    return (
      <div style={this.props.style}>
        {/* {this.state.tags.map((tag, index) => (
          <Chip
            variant="standard"
            key={index}
            label={tag.name}
            onDelete={() => this.removeIndex(index)}
          />
        ))} */}
        <Autocomplete
          id={'tags'}
          freeSolo
          key={this.state.tags}
          options={this.props?.options}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={(option, value) => option === value}
          defaultValue={this.props.defaultValue === '' ? '' : this.props.defaultValue}
          onChange={(event, newValue) => {
            this.onTagsChange(newValue);
          }}
          // onBlur={() => {
          //   this.props.handleChange(this.state.tags);
          // }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={this.props?.label}
              placeholder={this.props?.placeholder}
              margin="normal"
              defaultValue={this.props.defaultValue === '' ? '' : this.props.defaultValue}
              fullWidth
            />
          )}
        />
      </div>
    );
  }
}
