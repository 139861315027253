import React, { Component, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  Paper,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Fab,
  Icon
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ListRow from './Components/listRow.jsx';
import config from '../../constants/index.jsx';
import Postie from '../../helpers/postie';
import staticData from '../../helpers/staticdata';
import Auth from "../../helpers/authorization.jsx";
import NexusPagination from '../crudoperations/components/nexuspagination.jsx';

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
      filters: {},
      Types: [],
    };
  }
  componentDidMount() {
    this.loadData(1);
  }

  loadData = async (pageno) => {
    let Types = await staticData.getArenaTypeData('all');
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url =
        config.BASE_API_URL + 'crud/products/' +pageno+ '/' + Auth.urlTokenizer();
      let postData = { filters: this.state.filters };
      let json = await Postie.sendAsynchrnousCall(postData, url);

      if (json.status === 'success') {
        this.setState(
          {
            ...this.state,
            fields: json.data.fields,
            // data:
            //   typeof this.state.filters === 'object' && Object.keys(this.state.filters).length > 0
            //     ? json.data.data
            //     : [],
            data: json.data.data,
            apiCalls: json.data.apiCalls,
            children: json.data.children,
            addDiffrent: json.data.addDiffrent
          },
          function () {
            console.log(
              'loadData',
              this.state,
              this.state.filters,
              typeof this.state.filters === 'object',
              typeof this.state.filters === 'object' && Object.keys(this.state.filters).length > 0
            );
          }
        );
      }
    } else {
      console.log('userLogged', userLogged);
      window.location.href = '/session/signin';
    }
  };

  fetchUnitTypes = async () => {
    let json = await this.callForUnitTypes(this.state.Types);
    this.setState({
      isLoading: false,
      unitTypes: json,
      units: []
    });
  };
  changeProductType = async (e) => {
    console.log('changeProductType', e.target.value);
    let type = e.target.value;
    let postData = {
      type: type,
      authToken: localStorage.getItem(config.Token_name)
    };
    let serviceUrl = config.BASE_API_URL + 'material/arena/filterProducts';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      this.setState({ units: reply.data });
      console.log('replyData', reply.data);
    } else {
      Postie.showAlert(reply);
    }
  };
  openProductView = (id) => {
    let url = '/arena/product/view/' + id;
    console.log(url);
    Postie.navigateLink(url);
  };

  filterUnitTypes = (value) => {
    let unitTypeFilter = value.replaceAll(' ', '').replaceAll('x', '-');
    // Filter the units based on the filter text

    const filteredUnits = this.state.allRecords.filter((unit) => {
      return unit.sku.toLowerCase().includes(unitTypeFilter.toLowerCase());
    });

    // Update the unitTypes and units states with the filtered data
    this.setState(
      {
        units: filteredUnits
      },
      () => {
        if (unitTypeFilter === '') {
          // If filter input is empty, revert to previous state by fetching all units
          this.fetchUnits();
        }
      }
    );
  };
  addProduct = () => {
    let Url = 'products/add';
    console.log('addProduct', Url);
    Postie.openLink(Url);
  };
  render() {
    const { isLoading, unitTypes,data, validationFile, file, Types } = this.state;
    const unitsCount = data.length; // Get the number of units

    console.log('ProductList', Types);

    return (
      <Grid container spacing={3}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignSelf: 'center', marginLeft: '25px', marginTop: '40px' }}
        >
          <h4 className="m4">Filters</h4>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="typeLabel">Type</InputLabel>
            <Select
              labelId="typeLabel"
              id="type"
              label="Type"
              onChange={(e) => this.changeProductType(e)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Types && Types.map((type) => <MenuItem value={type.code}>{type.name}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="categoryLabel">Category</InputLabel>
            <Select labelId="categoryLabel" id="category" label="Category">
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="FilterLabel">Field For Filter</InputLabel>
            <Select labelId="FilterLabel" id="filterField" label="Choose Field">
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            size="medium"
            type="text"
            name="FiledValue"
            label="Value for Filter"
            variant="outlined"
            sx={{ mt: 1 }}
          />

          <Fab size="small" color="secondary" aria-label="Add" className="button" sx={{ m: 2 }}>
            <Icon>add</Icon>
          </Fab>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className="mt-5">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '40px'
                }}
              >
                <h3>Product Listing ({unitsCount})</h3>
                <Button variant="contained" color="primary" onClick={() => this.addProduct()}>
                  <AddIcon />
                  Add Product
                </Button>
              </div>

              {data?.records?.map((unit, index) => (
                <ListRow
                  product={unit}
                  index={index}
                  canView={true}
                  callback={(id) => this.openProductView(id)}
                />
              ))}
              <NexusPagination
            key={data}
            displayNote={data.display_note}
            currentPage={data.currentPage}
            totalPages={data.totalpages}
            handleFunction={(desiredPage) => this.loadData(desiredPage)}
          />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default ProductList;
