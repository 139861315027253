import React, { Component } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';
import UploadFile from '../files/uploadFile';
import FileView from '../bids/components/fileView';
import displayer from 'app/helpers/displayer';
import { Fragment } from 'react';
import Postie from 'app/helpers/postie';
import ReasonPopup from './ReasonPopup';

class GeneratorLeadRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openReasonPopup: false
    };
  }

  handleRejectLead = () => {
    this.setState({ ...this.state, openReasonPopup: true });
  };

  handleClosePiopup = () => {
    this.setState({ ...this.state, openReasonPopup: false });
  };

  render() {
    const { lead, showButtons } = this.props;

    return (
      <Fragment>
        <Grid
          container
          spacing={2}
          sx={{
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid',
            marginBottom: '22px'
          }}
        >
          <Grid item xs={12} sm={6} md={1} textAlign="center">
            <img
              key={lead?.organization?.logo}
              src={`${lead?.organization?.logo}`}
              alt="Organisation Logo"
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                objectFit: 'cover',
                display: 'block',
                margin: '0 auto'
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={1}>
            <label>Firm Name</label>
            <br />
            <label style={{ fontWeight: 'bold' }}>{lead?.organization?.name}</label>
            <br />
            <label>GST</label>
            <br />
            <label style={{ fontWeight: 'bold' }}>{lead?.organization?.gst_no}</label>
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <label>Person Name</label>
            <br />
            <label style={{ fontWeight: 'bold' }}>{lead?.name}</label>
            <br />
            <label>Address</label>
            <br />
            <label style={{ fontWeight: 'bold' }}>{lead?.organization?.address}</label>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <label>Email</label>
            <br />
            <label style={{ fontWeight: 'bold' }}>{lead?.email}</label>
            <br />
            <label>Phone</label>
            <br />
            <label style={{ fontWeight: 'bold' }}>{lead?.phone}</label>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <label>Verified Leads</label>
            <br />
            <label style={{ fontWeight: 'bold' }}>
              {lead?.organization?.verifified_leads === 1
                ? 'Provides Verified Leads'
                : 'Does Not Provide Verified Leads'}
            </label>
            <br />
            <label>Max Lead Sharing</label>
            <br />
            <label style={{ fontWeight: 'bold' }}>{lead?.organization?.max_lead_sharing}</label>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <label>Lead Type</label>
            <br />
            <label style={{ fontWeight: 'bold' }}>
              Deals in {lead?.organization?.lead_type} Property Type
            </label>
            <br />
            <label>Monthly Min Leads</label>
            <br />
            <label style={{ fontWeight: 'bold' }}>{lead?.organization?.min_leads}</label>
          </Grid>
          {showButtons && (
            <Grid item xs={12} sm={6} md={3}>
              <Grid container spacing={1}>
                {lead?.organization?.is_admin_verified === 0 && (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        style={{ backgroundColor: '#2c3e50', color: '#fff' }}
                        onClick={() => Postie.navigateLink(`/lead/approval/${lead?.id}`)}
                      >
                        Approve
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        style={{ backgroundColor: '#e74c3c', color: '#fff' }}
                        onClick={() => this.handleRejectLead()}
                      >
                        Reject
                      </Button>
                    </Grid>
                  </>
                )}
                {lead?.organization?.is_admin_verified === 1 && (
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: '#27ae60', color: '#fff' }}
                    >
                      Approved
                    </Button>
                  </Grid>
                )}
                {lead?.organization?.is_admin_verified === 2 && (
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: '#e74c3c', color: '#fff' }}
                    >
                      Rejected
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={2}>
            <label>Registered On</label>
            <label style={{ color: '#454545', margin: '5px' }}>
              {displayer.dateFormater(lead?.organization?.created_at)}
            </label>
          </Grid>
        </Grid>
        <ReasonPopup
          openPopup={this.state.openReasonPopup}
          closePopup={() => this.handleClosePiopup()}
          lead={lead}
        />
      </Fragment>
    );
  }
}

export default GeneratorLeadRow;
