import React, { Component } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  createTheme,
  Select,
  Switch
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Postie from '../../helpers/postie.jsx';
import config from '../../constants/index.jsx';
import displayer from 'app/helpers/displayer';
import Auth from 'app/helpers/authorization';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { CopyAll, Delete, Edit, Remove, RoundedCorner, ViewAgenda } from '@mui/icons-material';
import isToday from 'date-fns/isToday/index.js';
import staticData from 'app/helpers/staticdata.jsx';
import swal from 'sweetalert';
import { post } from 'jquery';
import Authorization from 'app/helpers/authorization';

const CodeRow = ({
  data,
  copyToClipBoard,
  openRules,
  openUsage,
  handleDeleteCoupen,
  handleSwitchVisiblity
}) => {
  const labelCode = { marginTop: '10px', color: 'grey' };
  return (
    <Grid
      container
      spacing={3}
      style={{
        width: '90%',
        padding: '10px',
        border: '1px solid',
        borderRadius: '10px',
        margin: '10px'
      }}
    >
      <Grid item md={11} xs={12}>
        <Grid container>
          <Grid item md="12" xs="12">
            <small>Coupon Code</small>
            <Typography variant="h3">
              {data?.code}
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => copyToClipBoard(e, data?.code)}
              >
                <CopyAll />
              </Button>
            </Typography>
          </Grid>
          <Grid item md="12" xs="12">
            <Typography variant="h6">{data?.description}</Typography>
          </Grid>
          <Grid item md={3} xs={6} sx={{ margin: '5px 0' }}>
            <small style={labelCode}>Type of Code</small>
            <Typography variant="body1">{data?.type}</Typography>
            <small style={labelCode}>Value</small>
            <Typography variant="body1">{data?.value}</Typography>
          </Grid>
          <Grid item md={3} xs={6} sx={{ margin: '5px 0' }}>
            <small style={labelCode}>Limit For Code can be used:</small>
            <Typography>{data?.total_usage}</Typography>
            <small style={labelCode}>Limit for User can use code:</small>
            <Typography>{data?.user_limit}</Typography>
          </Grid>
          <Grid item md={3} xs={6} sx={{ margin: '5px 0' }}>
            <small style={labelCode}>Start Date</small>
            <Typography gridColumn="span 2">{data?.start_date}</Typography>
            <small style={labelCode}>End Date</small>
            <Typography gridColumn="span 2">{data?.end_date}</Typography>
          </Grid>
          <Grid item md={3} xs={6} sx={{ margin: '5px 0' }}>
            <small style={labelCode}>Rules Applicable</small>
            <Typography gridColumn="span 2">{data?.rules?.length}</Typography>
            <small style={labelCode}>Used Count</small>
            <Typography gridColumn="span 2">{data?.usage?.length}</Typography>
          </Grid>
          <Grid item md={3} xs={6} sx={{ margin: '5px 0' }}>
            <small style={labelCode}>User Type</small>
            <Typography gridColumn="span 2">{data?.user_type}</Typography>
          </Grid>
          <Grid item md={3} xs={6} sx={{ margin: '5px 0' }}>
            <small style={labelCode}>Discount Parameter</small>
            <Typography gridColumn="span 2">{data?.discount_parameter}</Typography>
          </Grid>
          <Grid item md={3} xs={6} sx={{ margin: '5px 0' }}>
            <small style={labelCode}>Visible</small>
            <Grid>
              <Switch
                name="visible"
                checked={data.visible === 1}
                onChange={(e) => handleSwitchVisiblity(e)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={1} xs={12}>
        <Button
          style={{ margin: '5px' }}
          variant="contained"
          size="small"
          color="primary"
          onClick={() => Postie.navigateLink(`/discount/coupons/${data.id}`)}
        >
          <Edit />
        </Button>
        <Button
          style={{ margin: '5px' }}
          variant="contained"
          size="small"
          color="error"
          onClick={() => handleDeleteCoupen()}
        >
          <Delete />
        </Button>
      </Grid>
    </Grid>
  );
};

class CouponList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      skp_File: null,
      base_Image: null,
      is_finished: false,
      Types: [],
      formData: {},
      codes: [],
      isDialogOpen: false,
      rulesDialog: { show: false, data: [] },
      usageDialog: { show: false, data: [] },
      discountType: '',
      discountUserType: '',
      discountParameter: ''
    };
  }

  componentDidMount = () => {
    this.loadData();
    this.getEavData();
  };

  getEavData = async () => {
    const discountType = await staticData.getEavData('discount_type');
    const discountUserType = await staticData.getEavData('discount_user_type');
    const discountParameter = await staticData.getEavData('discount_parameter');
    this.setState({
      ...this.state,
      discountType: discountType,
      discountUserType: discountUserType,
      discountParameter: discountParameter
    });
  };

  loadData = async () => {
    const url = config.BASE_API_URL + 'coupon/list';
    const postData = { authToken: Auth.getToken() };
    let jsonData = await Postie.sendAsynchrnousCall(postData, url);
    if (jsonData.status === 'success') {
      this.setState({ ...this.state, codes: jsonData.data });
    }
    return jsonData;
  };

  toggleDialog = () => {
    this.setState({ isDialogOpen: !this.state.isDialogOpen });
  };
  copyToClipBoard = (e, copyText) => {
    const target = e.target;
    // Copy the text inside the text field
    //console.log('copyToClipBoard target',target);
    navigator.clipboard.writeText(copyText);
    target.append('Copied');
  };

  onHandleChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        formData: { ...this.state.formData, [name]: value }
      },
      function () {
        console.log('onHandleChange', name, value, this.state.formData);
      }
    );
  };

  onSave = async () => {
    const url = config.BASE_API_URL + 'coupon/add';
    const postData = this.state.formData;
    postData['authToken'] = Auth.getToken();
    let json = await Postie.sendAsynchrnousCall(postData, url);
    if (json.status === 'success') {
      this.loadData();
      this.toggleDialog();
    }
  };

  handleDeleteCoupen = async (coupenId) => {
    console.log('handleDeleteCoupen', coupenId);
    const willDelete = await swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this Discount coupen ?',
      buttons: ['No', 'YES'],
      icon: 'warning',
      dangerMode: true
    });

    if (willDelete) {
      const url = config.BASE_API_URL + 'coupon/update';
      const postData = {
        authToken: Auth.getToken(),
        field: 'status',
        value: '0',
        coupon_id: coupenId
      };
      let json = await Postie.sendAsynchrnousCall(postData, url);
      if (json.status === 'success') {
        this.loadData();
      }
    }
  };

  handleSwitchVisiblity = async (coupenId, e) => {
    const { name, checked } = e.target;
    console.log('handleSwitchVisiblity', name, checked);
    const url = config.BASE_API_URL + 'coupon/update';
    const postData = {
      authToken: Auth.getToken(),
      field: name,
      value: checked ? '1' : '0',
      coupon_id: coupenId
    };
    let json = await Postie.sendAsynchrnousCall(postData, url);
    if (json.status === 'success') {
      this.loadData();
    }
  };

  render() {
    const { discountType, formData, discountUserType, discountParameter } = this.state;
    console.log('formData', formData);
    return (
      <Grid container spacing={2} style={{ padding: '10px', margin: '10px', width: '99%' }}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Discount Codes Management</Typography>
          <Button variant="contained" color="primary" onClick={this.toggleDialog}>
            <AddIcon />
            Add Discount Code
          </Button>
        </Grid>

        <Grid item xs={12} sx={{ margin: '5px 0' }}>
          {this.state.codes &&
            this.state.codes.map((code) => (
              <CodeRow
                data={code}
                copyToClipBoard={(e, codeText) => this.copyToClipBoard(e, codeText)}
                openRules={(data) => this.openRules(data)}
                openUsage={(data) => this.openUsage(data)}
                handleDeleteCoupen={() => this.handleDeleteCoupen(code.id)}
                handleSwitchVisiblity={(e) => this.handleSwitchVisiblity(code.id, e)}
              />
            ))}
        </Grid>

        {/* Popup Dialog for Adding Discount Code */}
        <Dialog
          open={this.state.isDialogOpen}
          //onClose={this.toggleDialog}
          fullWidth={true}
          disableBackdropClick
        >
          <DialogTitle>Add Discount Code</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"> Select Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Type"
                name="type"
                onChange={this.onHandleChange}
              >
                {discountType &&
                  discountType.map((discount, index) => (
                    <MenuItem key={index} value={discount}>
                      {discount}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {['Percent'].includes(formData.type) && (
              <TextField
                type="number"
                min={1}
                autoFocus
                margin="dense"
                label="Enter Percentage Value (%)"
                name="value"
                onChange={this.onHandleChange}
                fullWidth
              />
            )}
            {['Fixed', 'Cashback'].includes(formData.type) && (
              <TextField
                type="number"
                min={1}
                autoFocus
                margin="dense"
                label="Enter Amount (₹)"
                name="value"
                onChange={this.onHandleChange}
                fullWidth
              />
            )}
            <FormControl fullWidth style={{ marginTop: '7px' }}>
              <InputLabel id="demo-simple-select-label"> Select User Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select User Type"
                name="user_type"
                onChange={this.onHandleChange}
              >
                {discountUserType &&
                  discountUserType.map((user, index) => (
                    <MenuItem key={index} value={user}>
                      {user}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: '7px' }}>
              <InputLabel id="demo-simple-select-label"> Select Discount Parameter</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Discount Parameter"
                name="discount_parameter"
                onChange={this.onHandleChange}
              >
                {discountParameter &&
                  discountParameter.map((parameter, index) => (
                    <MenuItem key={index} value={parameter}>
                      {parameter}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              autoFocus
              margin="dense"
              label="Discount Code"
              name="coupon"
              onChange={this.onHandleChange}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Description"
              name="description"
              onChange={this.onHandleChange}
              fullWidth
            />
            <TextField
              type="number"
              min={1}
              margin="dense"
              defaultValue={1000}
              label="Usage Limit"
              name="total_usage"
              onChange={this.onHandleChange}
              style={{ width: '50%' }}
            />
            <TextField
              type="number"
              min={1}
              margin="dense"
              defaultValue={1}
              label="User Limit"
              name="user_limit"
              onChange={this.onHandleChange}
              style={{ width: '50%' }}
            />
            <TextField
              type="date"
              min={isToday}
              margin="dense"
              label="Start Date"
              name="start_date"
              onChange={this.onHandleChange}
              style={{ width: '50%' }}
            />
            <TextField
              type="date"
              min={isToday}
              margin="dense"
              label="End Date"
              name="end_date"
              onChange={this.onHandleChange}
              style={{ width: '50%' }}
            />
            {/* Additional fields as required */}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleDialog} color="secondary">
              Cancel
            </Button>
            <Button onClick={() => this.onSave()} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default CouponList;
