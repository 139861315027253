import React, { Component } from 'react';
import Postie from '../../helpers/postie';
import config from '../../constants/index.jsx';
import ProductViewTab from './Components/productViewTab';
import { json } from 'react-router-dom';

class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      formData: {
        brand: '',
        name: '',
        width: '',
        depth: '',
        height: '',
        type: '',
        description: '',
        categories: [],
        custom_attribute: []
      }
    };
  }
  handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'custom_attributes') {
      let custom_attribute = [];
      custom_attribute.push(value);
      value = custom_attribute;
    }
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value
        }
      }),
      () => {
        console.log('handle input change', this.state.formData);
      }
    );
  };

  handleCategoryChange = (data, field) => {
    console.log('handleCategoryChange called', field, data);

    this.setState(
      {
        formData: {
          ...this.state.formData,
          [field]: data
        }
      },
      function () {
        console.log('handleCategoryChang:', this.state.formData);
      }
    );
  };

  productView = async () => {
    const { formData } = this.state;
    const authToken = localStorage.getItem(config.Token_name);
    const postData = {
      authToken,
      brand: formData.brand,
      type: formData.type,
      name: formData.name,
      width: formData.width,
      depth: formData.depth,
      height: formData.height,
      description: formData.description,
      categories: JSON.stringify(formData.categories),
      product_categories: JSON.stringify(formData.product_categories),
      custom_attribute: JSON.stringify(formData.custom_attribute)
    };

    //console.log('Post Data:', postData);
    let serviceUrl = config.BASE_API_URL + 'arena/create_product';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      let Url = '/arena/product/view/' + reply.data.id;
      //console.log('productView', Url);
      Postie.showAlert(reply);
      Postie.navigateLink(Url);
    } else {
      Postie.showAlert(reply);
    }
  };

  render() {
    const { formData } = this.state;
    return (
      <ProductViewTab
        key={formData}
        title="Add New Product"
        product={formData}
        hideButton={false}
        handleCategoryChange={(data, field) => this.handleCategoryChange(data, field)}
        handleInputChange={(event) => this.handleInputChange(event)}
        createProduct={() => this.productView()}
      />
    );
  }
}

export default AddProduct;
