import React, { Component } from 'react';
import { Grid, Button, TableCell, TableRow, TableBody, Table, Chip } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import Create from './create';
import Postie from 'app/helpers/postie';
import config from '../../constants/index.jsx';
import swal from 'sweetalert';
import FileView from '../bids/components/fileView';
import displayer from 'app/helpers/displayer';
import staticData from 'app/helpers/staticdata';
import ConnectModule from './connectModule';
import AddIcon from '@mui/icons-material/Add';
import LoadDropDown from './components/LoadDropdown';
import UploadFile from '../files/uploadFile';
import NexusPagination from './components/nexuspagination';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Tags from './components/Tags';
import ConnectionDataTable from './components/ConnectionDataTable';
import $ from 'jquery';
import Loading from 'app/components/MatxLoading';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  '& .closeButton': {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className="closeButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  '&.root': { padding: theme.spacing(2) }
}));
class Rud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Fields: this.props.fields,
      Data: this.props.data,
      apiCalls: this.props.apiCalls,
      updatedstate: false,
      connection: {
        makeConnection: false,
        data: [],
        editedData: []
      },
      filterType: {},
      showloading: false,
      importing: { show: false, status: 'In Progress', data: [], msg: '' }
    };
  }

  componentDidMount() {}

  handleFunction = (event, type, identifier) => {
    console.log('handleFunction called with type:', type);
    if (type.length > 0) {
      event.target['identifier'] = identifier;
      switch (type) {
        case 'create':
          this.createFunctionality(this.state.apiCalls['create'], event);
          break;
        case 'update':
          console.log(this.state.apiCalls['update']);
          this.updateFunctionality(this.state.apiCalls['update'], event);
          break;
        case 'delete':
          this.deleteFunctionality(this.state.apiCalls['update'], identifier);
          break;
        case 'connect':
          this.connectFunctionality(this.state.apiCalls['connect'], identifier);
          break;
        default:
          console.log('default Block Called');
          Postie.navigateLink(this.state.apiCalls[type]);
          break;
      }
    }
  };

  updateFunctionality = async (apiCall, event) => {
    console.log('event', event);
    if (apiCall.type === 'post') {
      if (event.target.value !== '' && event.target.value !== '\n') {
        let postData = this.generatePostData(apiCall, event);
        console.log('postData', postData);
        let serviceUrl = config.BASE_API_URL + apiCall.url;
        let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
        if (reply.status === 'success') {
          this.props.callback();
        } else {
          Postie.showAlert(reply);
        }
      }
    } else {
    }
  };
  createFunctionality = async (data) => {
    console.log('createFunctionality', data);
    let postData = data;
    postData['authToken'] = localStorage.getItem(config.Token_name);
    let serviceUrl = config.BASE_API_URL + this.state.apiCalls['create'].url;
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      if (this.state.apiCalls['create'].postSubmit) {
        this.postCreate(postData);
      }
      this.setState({ ...this.state, Data: reply.data.data, updatedstate: true });
    } else {
      Postie.showAlert(reply);
    }
  };
  postCreate = async (postData) => {
    let serviceUrl = config.BASE_API_URL + this.state.apiCalls['create'].postSubmit;
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
    } else {
      Postie.showAlert(reply);
    }
  };
  deleteFunctionality = (apiCall, identifier) => {
    swal({
      title: 'Are you sure?',
      text: 'You will not be able to recover data once deleted!',
      icon: 'warning',
      buttons: ['No, cancel it!', 'Yes, I am sure!'],
      dangerMode: true
    }).then((isConfirm) => {
      if (isConfirm) {
        let data = { target: { name: 'status', value: '0', identifier: identifier } };
        this.updateFunctionality(apiCall, data);
      }
    });
  };
  generatePostData = (apiCall, event) => {
    console.log('generatePostData', apiCall, event);
    let keyData = Object.keys(apiCall?.mandatory);
    let postData = {};
    for (var i = 0; i < keyData.length; i++) {
      if (apiCall.mandatory[keyData[i]] === 'token') {
        postData[[keyData[i]]] = localStorage.getItem(config.Token_name);
      } else {
        postData[[keyData[i]]] = event.target[apiCall.mandatory[keyData[i]]];
      }
    }
    return postData;
  };

  handleInputChange = (e) => {
    let selected = [];
    $('[name="' + e.target.name + '"]:checked').each((elem, input) => {
      selected.push(input.value);
    });
    let value = selected.join('|');
    //console.log('value', value);
    if (value === '') {
      value = 'None';
    }
    //console.log(e.target.name, selected.join('|'));
    let sarr = e.target.name.split('-');
    let elem = { target: { name: sarr[0], value: value } };
    this.handleFunction(elem, 'update', parseInt(sarr[1].substring(0, sarr[1].length - 2)));
  };

  filterdata = (event) => {
    let filterType = this.state.filterType;
    let { name, value } = event.target;
    if (typeof value === 'string' && value.toLowerCase() === 'none') {
      delete filterType[name];
    } else {
      filterType[name] = value;
    }
    this.setState({ ...this.state, filterType: filterType }, function () {
      //console.log('rud filterdata', event.target.value.toLowerCase(), this.state.filterType);
      this.props.filterData(this.state.filterType);
    });
  };

  handleProductCsv = async (file) => {
    console.log('file', file);
    let serviceUrl = config.BASE_API_URL + this.state.apiCalls['import'].url;
    let postData = {
      authToken: localStorage.getItem(config.Token_name),
      csv: file.data.url
    };
    this.setState(
      { showloading: true, importing: { show: true, status: 'In Progress', data: [], msg: '' } },
      async function () {
        let json = await Postie.sendAsynchrnousCall(postData, serviceUrl);
        if (json) {
          this.setState({
            showloading: false,
            importing: { show: true, status: json.status, msg: json.message, data: json.data }
          });
          this.props.callback();
        }
      }
    );
  };
  handleClose = () => {
    this.setState({
      ...this.state,
      showloading: false,
      showloading: true,
      importing: { show: false, status: 'In Progress', data: [], msg: '' }
    });
  };

  render() {
    let { Fields, data } = this.props;
    if (this.state.updatedstate) {
      data = this.state.Data;
    }
    const parentKeys = Object.keys(this.props.defaultValue);
    //console.log('Fields', this.props,parentKeys);
    return (
      <Grid container>
        <Grid
          item
          md={12}
          style={{ display: 'flex', alignItems: 'center', padding: '30px', marginLeft: '20px' }}
        >
          <Grid item md={6}>
            <h1>{this.props.heading.replace(/_/g, ' ')}</h1>
          </Grid>
          <Grid item md={3}>
            {/*console.log('addDifferent', this.props)*/}
            {this.props.addDifferent === true ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => Postie.navigateLink(this.state.apiCalls['create'].url)}
              >
                <AddIcon />
                Add Product
              </Button>
            ) : this.state?.apiCalls['create'] ? (
              <Create
                heading={this.props.heading}
                fields={Fields}
                apiCall={this.state?.apiCalls['create']}
                handleFile={(file, type) => this.props.handleFile(file, type)}
                add={(data) => this.createFunctionality(data)}
                defaultValue={this.props.defaultValue}
                filters={this.state.filterType}
              />
            ) : null}
          </Grid>
          <Grid item md={3}>
            {this.state.apiCalls['import'] && (
              <UploadFile
                style={{ float: 'right' }}
                filename={'product csv'}
                displayTosection={false}
                areaName={'CSV'}
                type={'product_csv'}
                projectId={0}
                areaId={0}
                callback={(File) => this.handleProductCsv(File)}
              />
            )}
            {this.state.apiCalls['import'] && this.state.importing.show && (
              <Dialog
                onClose={() => this.handleClose()}
                aria-labelledby="customized-dialog-title"
                open={this.state.importing.show}
                maxWidth="md" // Set the maxWidth to control the width of the dialog box
                fullWidth // This ensures that the dialog takes up the full width
              >
                <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                  Importing Products
                </DialogTitle>
                <DialogContent dividers>
                  {this.state.showloading ? (
                    <Loading />
                  ) : (
                    <>
                      <h3>
                        {this.state.importing.status.toUpperCase()}:{this.state.importing.msg}
                      </h3>
                      <ul>
                        {this.state.importing.data.map((log) => (
                          <li sx={{ marginBottom: '3px', padding: '5px' }}>{log}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </DialogContent>
              </Dialog>
            )}
          </Grid>
        </Grid>

        <Grid item md={12} style={{ padding: '30px' }}>
          <ConnectionDataTable
            key={data}
            data={data}
            fields={Fields}
            isConnect={false}
            isApiFilterable={true}
            showSuggesstion={false}
            apiCalls={this.state.apiCalls}
            callback={(e, action, id) => this.handleFunction(e, action, id)}
            heading={this.props.heading}
            filters={this.props.defaultValue}
            userFilters={this.state.filterType}
            filterData={(event) => this.filterdata(event)}
          />
          <NexusPagination
            key={data}
            displayNote={data.display_note}
            currentPage={data.currentPage}
            totalPages={data.totalpages}
            handleFunction={(desiredPage) => this.props.changePage(desiredPage, this.props.heading)}
          />
        </Grid>
      </Grid>
    );
  }
}

export default Rud;
