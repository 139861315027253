import React, { Component } from 'react';
import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import UploadFile from '../files/uploadFile';
import FileView from '../bids/components/fileView';
import displayer from 'app/helpers/displayer';
import AICaptioner from '../crudoperations/components/AICaptioner';
import AIReplacer from '../crudoperations/components/AIReplacer';
import { Fragment } from 'react';
import ConnectModule from '../crudoperations/connectModule';
import Authorization from 'app/helpers/authorization';
import staticData from 'app/helpers/staticdata';
import Postie from 'app/helpers/postie';
import config from '../../constants/index';
import Auth from 'app/helpers/authorization';
import ConnectGallery from '../crudoperations/connectGallery';
import GeneratorLeadRow from './GeneratorLeadRow';

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadList: '',
      filter: '',
      projectType: ''
    };
  }

  componentDidMount = async () => {
    this.loadData({ approval: 'Pending' });
    //this.getProjectTypeData();
  };
  loadData = async (filter = '') => {
    const postData = {
      authtoken: Auth.getToken(),
      role: 'Lead Generator',
      filters: filter
    };
    const url = config.BASE_API_URL + 'admin/getusers';
    let jsonData = await Postie.sendAsynchrnousCall(postData, url);
    if (jsonData.status === 'success') {
      this.setState({ ...this.state, leadList: jsonData.data });
    }
  };
  handleApplyFilter = (e) => {
    const { name, value } = e.target;
    console.log('handleApplyFilter', name, value);
    this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          [name]: value
        }
      }),
      () => {
        this.loadData(this.state.filter);
      }
    );
  };
  // getProjectTypeData = async () => {
  //   try {
  //     let projectTypeData = await staticData.getEavData('project_type');
  //     this.setState({ projectType: projectTypeData }, function () {
  //       console.log('getProjectTypeData', this.state.projectType);
  //     });
  //   } catch (error) {
  //     console.error('Error loading project type data:', error);
  //   }
  // };

  render() {
    const { leadList, projectType } = this.state;
    console.log('projectType', projectType);

    return (
      <Fragment>
        <Grid container spacing={2} sx={{ margin: '10px', padding: '5px' }}>
          <Grid item xs={12} sm={12} md={12}>
            <h1>New User Registrations</h1>
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <label style={{ font: 'bold' }}>Firm Name</label>
            <TextField
              type="text"
              label="Firm Name"
              name="firm_name"
              onChange={this.handleApplyFilter}
              style={{ width: '90%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <label style={{ font: 'bold' }}>Contact Person Name</label>
            <TextField
              type="text"
              label="Contact Person Name"
              name="contact_name"
              onChange={this.handleApplyFilter}
              style={{ width: '90%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <div>
              <label style={{ font: 'bold' }}>City</label>
            </div>
            <TextField
              type="text"
              label="city"
              name="city"
              onChange={this.handleApplyFilter}
              style={{ width: '90%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <label style={{ font: 'bold' }}>Filtered leads</label>
            <FormControl style={{ width: '90%' }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Verified leads"
                name="filtered_leads"
                onChange={this.handleApplyFilter}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <label style={{ font: 'bold' }}>Property Type</label>
            <FormControl style={{ width: '90%' }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Property Type"
                name="property_type"
                onChange={this.handleApplyFilter}
              >
                <MenuItem value="">Select Property Type</MenuItem>
                <MenuItem value="Residential">Residential</MenuItem>
                <MenuItem value="Commercial">Commercial</MenuItem>
                <MenuItem value="Corporate">Corporate</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={1}>
            <label style={{ font: 'bold' }}>Verified</label>
            <FormControl style={{ width: '90%' }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="verified"
                name="verified"
                onChange={this.handleApplyFilter}
              >
                <MenuItem value="">Select </MenuItem>
                <MenuItem value="Verified">Verified</MenuItem>
                <MenuItem value="Not Verified">Not Verified</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={12} sm={6} md={2}>
            <label style={{ font: 'bold' }}>Approved/Unapproved</label>
            <FormControl style={{ width: '90%' }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Approval"
                name="approval"
                onChange={this.handleApplyFilter}
              >
                <MenuItem value="">Select </MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid style={{ margin: '22px', padding: '3px' }}>
          {leadList &&
            leadList.map((lead, index) => (
              <GeneratorLeadRow key={index} lead={lead} showButtons={true} />
            ))}
        </Grid>
      </Fragment>
    );
  }
}

export default AdminDashboard;
