import React, { Component } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  createTheme,
  Select,
  Switch
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Postie from '../../helpers/postie.jsx';
import config from '../../constants/index.jsx';
import displayer from 'app/helpers/displayer';
import Auth from 'app/helpers/authorization';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { CopyAll, Delete, Edit, Remove, RoundedCorner, ViewAgenda } from '@mui/icons-material';
import isToday from 'date-fns/isToday/index.js';
import staticData from 'app/helpers/staticdata.jsx';
import swal from 'sweetalert';
import { post } from 'jquery';
import Authorization from 'app/helpers/authorization';
import AddRulePopup from './addRulePopup.jsx';

const CodeRow = ({
  data,
  copyToClipBoard,
  handleEditCoupen,
  editMode,
  updateCoupen,
  discountType,
  discountUserType,
  discountParameter,
  handleCloseCoupen,
  setCopied,
  handleSwitchVisiblity
}) => {
  const labelCode = { marginTop: '10px', color: 'grey' };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: '100%',
        maxWidth: '1200px',
        padding: '20px',
        margin: 'auto',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px'
      }}
    >
      <Grid item md={11} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Coupon Code
            </Typography>
            <Typography variant="h4" display="flex" alignItems="center">
              {data?.code}
              {setCopied ? (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: 2 }}
                  onClick={(e) => copyToClipBoard(e, data?.code)}
                >
                  Copied
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: 2 }}
                  onClick={(e) => copyToClipBoard(e, data?.code)}
                >
                  <CopyAll />
                </Button>
              )}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {editMode ? (
              <TextField
                margin="dense"
                label="Description"
                name="description"
                defaultValue={data?.description}
                onChange={(e) => updateCoupen(e)}
                fullWidth
              />
            ) : (
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {data?.description}
              </Typography>
            )}
          </Grid>

          <Grid item md={3} xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              Type of Code
            </Typography>
            {editMode ? (
              <Select
                fullWidth
                defaultValue={data?.type}
                name="type"
                onChange={(e) => updateCoupen(e)}
              >
                {discountType.map((discount, index) => (
                  <MenuItem key={index} value={discount}>
                    {discount}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {data?.type}
              </Typography>
            )}
          </Grid>

          <Grid item md={3} xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              Value
            </Typography>
            {editMode ? (
              <TextField
                type="number"
                margin="dense"
                label="Enter Value"
                defaultValue={data?.value}
                name="value"
                onChange={(e) => updateCoupen(e)}
                fullWidth
              />
            ) : (
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {data?.value}
              </Typography>
            )}
          </Grid>

          <Grid item md={3} xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              Limit For Code can be used
            </Typography>
            {editMode ? (
              <TextField
                type="number"
                margin="dense"
                defaultValue={data?.total_usage}
                label="Usage Limit"
                name="total_usage"
                onChange={(e) => updateCoupen(e)}
                fullWidth
              />
            ) : (
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {data?.total_usage}
              </Typography>
            )}
          </Grid>

          <Grid item md={3} xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              Limit for User can use code
            </Typography>
            {editMode ? (
              <TextField
                type="number"
                margin="dense"
                defaultValue={data?.user_limit}
                label="User Limit"
                name="user_limit"
                onChange={(e) => updateCoupen(e)}
                fullWidth
              />
            ) : (
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {data?.user_limit}
              </Typography>
            )}
          </Grid>

          <Grid item md={3} xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              User Type
            </Typography>
            {editMode ? (
              <Select
                fullWidth
                defaultValue={data?.user_type}
                name="user_type"
                onChange={(e) => updateCoupen(e)}
              >
                {discountUserType.map((user, index) => (
                  <MenuItem key={index} value={user}>
                    {user}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {data?.user_type}
              </Typography>
            )}
          </Grid>

          <Grid item md={3} xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              Discount Parameter
            </Typography>
            {editMode ? (
              <Select
                fullWidth
                defaultValue={data?.discount_parameter}
                name="discount_parameter"
                onChange={(e) => updateCoupen(e)}
              >
                {discountParameter.map((parameter, index) => (
                  <MenuItem key={index} value={parameter}>
                    {parameter}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {data?.discount_parameter}
              </Typography>
            )}
          </Grid>

          <Grid item md={3} xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              Used Count
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              {data?.usage?.length}
            </Typography>
          </Grid>

          <Grid item md={3} xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              Start Date
            </Typography>
            {editMode ? (
              <TextField
                type="date"
                margin="dense"
                label="Start Date"
                name="start_date"
                defaultValue={data?.start_date}
                onChange={(e) => updateCoupen(e)}
                fullWidth
              />
            ) : (
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {data?.start_date}
              </Typography>
            )}
          </Grid>

          <Grid item md={3} xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              End Date
            </Typography>
            {editMode ? (
              <TextField
                type="date"
                margin="dense"
                label="End Date"
                name="end_date"
                defaultValue={data?.end_date}
                onChange={(e) => updateCoupen(e)}
                fullWidth
              />
            ) : (
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {data?.end_date}
              </Typography>
            )}
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              Visible
            </Typography>
            <Switch
              name="visible"
              checked={data.visible === 1}
              onChange={(e) => handleSwitchVisiblity(e)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        md={1}
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {editMode ? (
          <Button variant="contained" size="small" color="secondary" onClick={handleCloseCoupen}>
            Close Edit Mode
          </Button>
        ) : (
          <Button variant="contained" size="small" color="primary" onClick={handleEditCoupen}>
            <Edit />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

class CouponDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      skp_File: null,
      base_Image: null,
      is_finished: false,
      Types: [],
      formData: {},
      codeDetails: [],
      isDialogOpen: false,
      rulesDialog: { show: false, data: [] },
      usageDialog: { show: false, data: [] },
      discountType: '',
      discountUserType: '',
      discountParameter: '',
      editMode: false,
      ruleTypes: '',
      rule_type: '',
      addRulePopup: false,
      productCategories: '',
      categories: '',
      setCopied: false,
      productData: '',
      locations: '',
      loading: true
    };
  }

  componentDidMount = async () => {
    const queryParams = displayer.getUrlParams('/discount/coupons/');
    await Promise.all([this.loadData(queryParams[0]), this.getEavData(), this.getCategoriesData()]);
    this.setState({ loading: false });
  };

  getEavData = async () => {
    const discountType = await staticData.getEavData('discount_type');
    const discountUserType = await staticData.getEavData('discount_user_type');
    const discountParameter = await staticData.getEavData('discount_parameter');
    const ruleTypes = await staticData.getEavData('rule_types');
    const locations = await staticData.getEavData('locations');
    this.setState({
      ...this.state,
      discountType: discountType,
      discountUserType: discountUserType,
      discountParameter: discountParameter,
      ruleTypes: ruleTypes,
      locations: locations
    });
  };

  getCategoriesData = async () => {
    const url = config.BASE_API_URL + 'arena/categories/' + Auth.getToken();
    let jsonData = await Postie.fetchUrl(url);
    if (jsonData) {
      this.setState({ ...this.state, categories: jsonData });
    }
  };
  getProductData = async (filter) => {
    const postData = {
      filters: filter
    };
    const url = config.BASE_API_URL + 'product/filter/1';
    let jsonData = await Postie.sendAsynchrnousCall(postData, url);
    if (jsonData.status === 'success') {
      this.setState({ ...this.state, productData: jsonData.data });
    }
  };

  getProductCategoriesData = async (filters) => {
    const url = config.BASE_API_URL + 'arena/Filters/' + filters;
    let jsonData = await Postie.fetchUrl(url);
    if (jsonData) {
      this.setState({ ...this.state, productCategories: jsonData });
    }
  };

  loadData = async (coupenId) => {
    const url = config.BASE_API_URL + 'coupon/detail/' + coupenId;
    let jsonData = await Postie.fetchUrl(url);
    if (jsonData) {
      this.setState({ ...this.state, codeDetails: jsonData });
    }
    return jsonData;
  };

  handleAddRule = () => {
    this.setState({ isDialogOpen: !this.state.isDialogOpen });
  };
  copyToClipBoard = (e, copyText) => {
    const target = e.target;
    // Copy the text inside the text field
    //console.log('copyToClipBoard target',target);
    navigator.clipboard.writeText(copyText);
    target.append('Copied');
    this.setState({ setCopied: true });
  };

  onHandleChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        formData: { ...this.state.formData, [name]: value }
      },
      function () {
        console.log('onHandleChange', name, value, this.state.formData);
      }
    );
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  onSubmitRule = async (type, value) => {
    const url = config.BASE_API_URL + 'coupon/addRule';
    const postData = {
      authToken: Auth.getToken(),
      type: type,
      value: value,
      coupon_id: this.state.codeDetails.id
    };
    let json = await Postie.sendAsynchrnousCall(postData, url);
    if (json.status === 'success') {
      const queryParams = displayer.getUrlParams('/discount/coupons/');
      this.loadData(queryParams[0]);
      this.handleAddRule();
      this.closeAddRulePopup();
    }
  };

  handleEditCoupen = async () => {
    this.setState({
      editMode: true
    });
  };
  handleCloseCoupen = async () => {
    this.setState({
      editMode: false
    });
  };

  handleUpdateCoupen = async (coupenId, e) => {
    const { name, value } = e.target;
    const url = config.BASE_API_URL + 'coupon/update';
    const postData = {
      authToken: Auth.getToken(),
      field: name,
      value: value,
      coupon_id: coupenId
    };
    let json = await Postie.sendAsynchrnousCall(postData, url);
    if (json.status === 'success') {
      this.loadData();
    }
  };

  handleSelectRuleType = async (e) => {
    const { name, value } = e.target;
    this.setState({
      rule_type: value
    });
    this.setState({
      addRulePopup: true
    });
  };

  closeAddRulePopup = () => {
    this.setState({
      addRulePopup: false
    });
  };

  handleDeleteRule = async (ruleId) => {
    const willDelete = await swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this Discount Rule ?',
      buttons: ['No', 'YES'],
      icon: 'warning',
      dangerMode: true
    });

    if (willDelete) {
      const url = config.BASE_API_URL + 'coupon/updateRule';
      const postData = {
        authToken: Auth.getToken(),
        field: 'status',
        value: '0',
        rule_id: ruleId
      };
      let json = await Postie.sendAsynchrnousCall(postData, url);
      if (json.status === 'success') {
        const queryParams = displayer.getUrlParams('/discount/coupons/');
        this.loadData(queryParams[0]);
      }
    }
  };

  handleSwitchVisiblity = async (coupenId, e) => {
    const { name, checked } = e.target;
    console.log('handleSwitchVisiblity', name, checked);
    const url = config.BASE_API_URL + 'coupon/update';
    const postData = {
      authToken: Auth.getToken(),
      field: name,
      value: checked ? '1' : '0',
      coupon_id: coupenId
    };
    let json = await Postie.sendAsynchrnousCall(postData, url);
    if (json.status === 'success') {
      const queryParams = displayer.getUrlParams('/discount/coupons/');
      this.loadData(queryParams[0]);
    }
  };

  render() {
    const {
      discountType,
      formData,
      discountUserType,
      discountParameter,
      codeDetails,
      editMode,
      rule_type,
      ruleTypes,
      productCategories,
      categories,
      productData,
      locations,
      loading
    } = this.state;
    console.log('codeDetails', codeDetails, this.state.loading);
    return loading ? (
      <Grid>
        <h2>Loading....</h2>
      </Grid>
    ) : (
      <Grid container spacing={2} style={{ padding: '10px', margin: '10px', width: '99%' }}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Coupon Details</Typography>
        </Grid>

        <Grid item xs={12} sx={{ margin: '5px 0' }}>
          {codeDetails && (
            <CodeRow
              data={codeDetails}
              copyToClipBoard={(e, codeText) => this.copyToClipBoard(e, codeText)}
              openRules={(data) => this.openRules(data)}
              openUsage={(data) => this.openUsage(data)}
              handleEditCoupen={() => this.handleEditCoupen(codeDetails.id)}
              editMode={editMode}
              updateCoupen={(e) => this.handleUpdateCoupen(codeDetails.id, e)}
              discountType={discountType}
              discountUserType={discountUserType}
              discountParameter={discountParameter}
              handleCloseCoupen={() => this.handleCloseCoupen()}
              setCopied={this.state.setCopied}
              handleSwitchVisiblity={(e) => this.handleSwitchVisiblity(codeDetails.id, e)}
            />
          )}
        </Grid>
        <Grid item xs={12} sx={{ margin: '10px 0' }}>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              paddingBottom: '10px',
              borderBottom: '2px solid #eee'
            }}
          >
            <Typography variant="h4" sx={{ marginBottom: '10px' }}>
              Coupon Rules
            </Typography>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => this.handleAddRule()}
              sx={{
                padding: '6px 12px',
                marginBottom: '10px',
                textTransform: 'none'
              }}
            >
              Add Rule
            </Button>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Rule Type
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Rule Value
              </Typography>
            </Grid>
          </Grid>

          {codeDetails &&
            codeDetails?.rules?.map((rule, index) => (
              <Grid container spacing={2} key={index} sx={{ marginTop: '8px' }}>
                <Grid item xs={12} md={3}>
                  <Typography variant="body1">{rule.type}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {rule.type === 'product' ? (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => Postie.openLink(`/arena/product/view/${rule.value}`)}
                      sx={{ padding: '6px 12px', textTransform: 'none' }}
                    >
                      {rule.value}
                    </Button>
                  ) : (
                    <Typography variant="body1">{rule.value}</Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    variant="contained"
                    size="small"
                    color="warning"
                    onClick={() => this.handleDeleteRule(rule.id)}
                    sx={{ padding: '6px 12px', textTransform: 'none' }}
                  >
                    <Delete />
                  </Button>
                </Grid>
              </Grid>
            ))}
        </Grid>

        {/* Popup Dialog for Adding Discount Code */}
        <Dialog
          open={this.state.isDialogOpen}
          //onClose={this.toggleDialog}
          fullWidth={true}
          disableBackdropClick
        >
          <DialogTitle>Select Rule Type For Coupon</DialogTitle>
          <DialogContent>
            <FormControl fullWidth style={{ marginTop: '7px' }}>
              <InputLabel id="demo-simple-select-label"> Select Rule Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Rule Type"
                name="rule_type"
                onChange={(e) => this.handleSelectRuleType(e)}
              >
                {ruleTypes &&
                  ruleTypes.map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAddRule} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.addRulePopup && (
          <AddRulePopup
            openPopup={this.state.addRulePopup}
            closePopup={() => this.closeAddRulePopup()}
            ruleType={rule_type}
            onSubmitRule={(ruleValue) => this.onSubmitRule(rule_type, ruleValue)}
            productCategories={productCategories}
            categories={categories}
            getCategoryData={(productCategoryname) =>
              this.getProductCategoriesData(productCategoryname)
            }
            submitCombineData={(rule_type, ruleValue) => this.onSubmitRule(rule_type, ruleValue)}
            closeRulePopup={() => this.handleAddRule()}
            getProductData={(filter) => this.getProductData(filter)}
            productData={productData}
            locations={locations}
          />
        )}
      </Grid>
    );
  }
}

export default CouponDetails;
