import React, { Component } from 'react';
import { Grid, TextField, Button, FormControl } from '@mui/material';
import CategoryTreeView from './Components/categoryTreeView';
import staticData from 'app/helpers/staticdata';
import Tags from '../crudoperations/components/Tags';
import LoadDropDown from '../crudoperations/components/LoadDropdown';
import Postie from '../../helpers/postie';
import config from '../../constants/index.jsx';
import Divider from '@mui/material/Divider';
import UploadFile from '../files/uploadFile';
import FileView from '../bids/components/fileView';
import displayer from 'app/helpers/displayer';

class AddBulkProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Fields: this.props.fields,
      Data: this.props.data,
      apiCalls: this.props.apiCalls,
      Types: [],
      brand: [],
      isCreateVisible: false, // Initialize it as false
      categories: [],
      subcategories: [],
      workCategories: [],
      vendors: [],
      selectedImages: [],
      vendorLabel: '',
      productData: {
        brand: '',
        vendor: '',
        length: '',
        width: '',
        depth: '',
        markup: '',
        mou: '',
        etd: '',
        gst_per: '',
        gst_incl: '',
        description: '',
        products: [
          {
            name: '',
            code: '',
            mrp: '',
            nlc: '',
            image: ''
          }
        ]
      }
    };
  }
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    let categories = await staticData.getAutoCompleteData('product_categories');
    let subcategories = await staticData.getAutoCompleteData('custom_attributes');
    const workCategories = await staticData.getArenaCategories();
    const type = await staticData.getEavData('modular_type');
    let brand = await staticData.getArenaBrandName('');
    let vendors = await staticData.getVendorList('');

    if (vendors.length > 0) {
      vendors.forEach((vendor) => {
        vendor['vendor_name'] = vendor.vendor.vendor_name;
      });
    }
    this.setState({
      categories,
      subcategories,
      brand,
      workCategories,
      vendors,
      type
    });
  };

  handleInputChange = (e) => {
    // Handle form field changes and update the state
    const { name, value } = e.target;
    this.setState(
      (prevState) => ({
        productData: {
          ...prevState.productData,
          [name]: value
        }
      }),
      function () {
        console.log('selected data', name, value);
      }
    );
  };
  handleProductChange = (e, index) => {
    // Handle form field changes and update the state
    const Products = this.state.productData.products;
    Products[index][e.target.name] = e.target.value;
    this.setState({
      productData: { ...this.state.productData, products: Products }
    });
  };

  handleAddProduct = () => {
    const products = this.state.productData.products;
    console.log('products', products);
    products.push({
      name: '',
      code: '',
      mrp: '',
      nlc: '',
      image: ''
    });

    const selectedImages = this.state.selectedImages;
    selectedImages.push(null);

    this.setState({
      productData: { ...this.state.productData, products: products, selectedImages }
    });
  };

  handleFile = (resp, type, productIndex) => {
    if (type === 'base_image') {
      const Products = this.state.productData.products;
      Products[productIndex]['image'] = resp.data.url;
      this.setState({
        productData: { ...this.state.productData, products: Products }
      });
    }
  };

  onAddBulkProduct = async () => {
    const { productData } = this.state;
    const authToken = localStorage.getItem(config.Token_name);
    const postData = productData;
    postData['authToken'] = authToken;
    let serviceUrl = config.BASE_API_URL + 'arena/product/bulkadd';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      Postie.showAlert(reply);
      this.setState({
        productData: {
          brand: '',
          vendor: '',
          length: '',
          width: '',
          markup: '',
          depth: '',
          mou: '',
          etd: '',
          gst_per: '',
          gst_incl: '',
          description: '',
          products: [
            {
              name: '',
              code: '',
              mrp: '',
              nlc: '',
              image: ''
            }
          ]
        }
      });
    } else {
      Postie.showAlert(reply);
    }
  };

  render() {
    const { categories, subcategories, vendors, workCategories, brand, productData, type } =
      this.state;
    //console.log('Bulk Product data categories:', categories);
    //console.log('Bulk Product data subcategories:', subcategories);
    // console.log('Bulk Product data vendors:', vendors);
    // console.log('Bulk Product data workCategories:', workCategories);
    //console.log('Bulk Product data productData:', productData);

    return (
      <Grid
        container
        spacing={3}
        style={{
          marginLeft: '20px'
        }}
      >
        <Grid item xs={12} sm={12} md={4}>
          <h4 className="m4">{this.props.name}</h4>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                {brand && brand.length > 0 && (
                  <LoadDropDown
                    record={{ label: 'Brand' }}
                    defaultValue={this.state.productData?.brand}
                    callback={this.handleInputChange}
                    ftype={{
                      dbname: 'brand',
                      placeholder: 'Select Brand',
                      optiontype: 'object',
                      optionMap: { value: 'id', label: 'brand_name' },
                      options: brand
                    }}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                {vendors && vendors.length > 0 && (
                  <LoadDropDown
                    record={{ label: 'Vendors' }}
                    defaultValue={this.state.productData?.vendor}
                    callback={this.handleInputChange}
                    ftype={{
                      dbname: 'vendors',
                      placeholder: 'Select Vendors',
                      optiontype: 'object',
                      optionMap: { value: 'id', label: 'vendor_name' },
                      options: vendors
                    }}
                  />
                )}
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  name="width"
                  id="width"
                  defaultValue={this.props.productData?.width}
                  onChange={this.handleInputChange}
                  errorMessages={['this field is required']}
                  label=" width(MM)"
                  validators={['required', 'minStringLength: 4']}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  name="depth"
                  id="depth"
                  defaultValue={this.props.productData?.depth}
                  onChange={this.handleInputChange}
                  errorMessages={['this field is required']}
                  label=" depth(MM)"
                  validators={['required', 'minStringLength: 4']}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  name="height"
                  id="height"
                  defaultValue={this.props.productData?.height}
                  onChange={this.handleInputChange}
                  errorMessages={['this field is required']}
                  label=" height(MM)"
                  validators={['required', 'minStringLength: 4']}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  size="medium"
                  multiline
                  rows={4}
                  type="text"
                  name="description"
                  label="Product Description"
                  variant="outlined"
                  sx={{ mt: 1 }}
                  defaultValue={this.props.productData?.description}
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Tags
                key={this.props.product?.custom_attribute}
                options={subcategories}
                label={`Custom Attributes    (Ex: Field : value)`}
                placeholder={`Attributes to be added in `}
                baseModel={'custom_attributes'}
                defaultValue={
                  this.props.product?.custom_attribute ? this.props.product?.custom_attribute : []
                }
                handleChange={(data) => {
                  let elem = {
                    target: { name: 'custom_attribute', value: JSON.stringify(data) }
                  };
                  console.log('tags', elem);
                  this.handleInputChange(elem);
                }}
              />
            </FormControl>
          </Grid>
          <Grid container xs={12} md={12}>
            <Grid item xs={4} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  style={{ margin: '8px' }}
                  type="number"
                  name="markup"
                  id="standard-basic"
                  defaultValue={this.state.productData?.markup}
                  onChange={this.handleInputChange}
                  errorMessages={['this field is required']}
                  label=" Markup%"
                  validators={['required', 'minStringLength: 4']}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  style={{ margin: '8px' }}
                  type="number"
                  name="mou"
                  id="mou"
                  defaultValue={this.props.product?.mou}
                  onChange={this.handleInputChange}
                  errorMessages={['this field is required']}
                  label=" Mou"
                  validators={['required', 'minStringLength: 4']}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  style={{ margin: '8px' }}
                  type="number"
                  name="etd"
                  id="etd"
                  defaultValue={this.props.product?.mou}
                  onChange={this.handleInputChange}
                  errorMessages={['this field is required']}
                  label=" ETD (In Days)"
                  validators={['required', 'minStringLength: 4']}
                />
              </FormControl>
            </Grid>

            <Grid item xs={4} sm={6} md={6}>
              <FormControl fullWidth>
                <LoadDropDown
                  record={{ label: 'Modular Connection Type' }}
                  defaultValue={''}
                  callback={this.handleInputChange}
                  ftype={{
                    dbname: 'type',
                    placeholder: ' Connection Type',
                    optiontype: 'array',
                    options: type
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={6} md={6}>
              <FormControl fullWidth>
                <LoadDropDown
                  record={{ label: 'gst %' }}
                  defaultValue={''}
                  callback={this.handleInputChange}
                  ftype={{
                    dbname: 'gst_per',
                    placeholder: ' Gst %',
                    optiontype: 'array',
                    options: [6, 12, 18]
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={6} md={6}>
              <FormControl fullWidth>
                <LoadDropDown
                  record={{ label: 'gst Included' }}
                  defaultValue={''}
                  callback={this.handleInputChange}
                  ftype={{
                    dbname: 'gst_incl',
                    placeholder: ' Gst Included',
                    optiontype: 'array',

                    options: ['Yes', 'No']
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <h4 className="m4">Product Category</h4>
          <CategoryTreeView
            key={categories}
            data={categories}
            field="name"
            value="id"
            name="product_category"
            selected={
              this.props.product?.product_categories
                ? { data: this.props.product?.product_categories, objectName: '' }
                : { data: [], objectName: '' }
            }
            //onItemClick={(data) => this.props.handleCategoryChange(data, 'product_categories')}
            onItemClick={(data) => {
              let elem = {
                target: { name: 'product_categories', value: data }
              };
              this.handleInputChange(elem);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <h4 className="m4">Work Category</h4>
          {/* <AddIcon onClick={() => this.setState({ isCreateVisible: true })} /> */}
          <CategoryTreeView
            key={workCategories}
            data={workCategories}
            field="sow"
            value="id"
            selected={
              this.props.product?.categories
                ? { data: this.props.product?.categories, objectName: 'category_id' }
                : { data: [], objectName: 'category_id' }
            }
            onItemClick={(data) => {
              let elem = {
                target: { name: 'categories', value: data }
              };
              this.handleInputChange(elem);
            }}
          />
        </Grid>
        <Divider />
        <Grid
          container
          spacing={3}
          style={{
            marginLeft: '20px',
            marginTop: '10px'
          }}
        >
          <h3>Products</h3>
          <Grid item xs={12} md={12}>
            <div>
              {productData?.products?.map((product, index) => (
                <ProductDetailsForm
                  key={index}
                  product={product}
                  handleChange={(elem) => this.handleProductChange(elem, index)}
                  handleFile={(File) => this.handleFile(File, 'base_image', index)}
                />
              ))}
            </div>
            <Button size="lg" variant="contained" color="primary" onClick={this.handleAddProduct}>
              Add more
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={4} md={12}>
          <div style={{ alignSelf: 'center', marginTop: '40px' }}>
            <Button size="lg" variant="contained" color="primary" onClick={this.onAddBulkProduct}>
              Submit All
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  }
}
class ProductDetailsForm extends Component {
  render() {
    const { index } = this.props;

    return (
      <Grid container spacing={3}>
        <Grid item xs={4} sm={4} md={2}>
          <FormControl fullWidth>
            <TextField
              type="text"
              name="name"
              id="standard-basic"
              defaultValue={this.props.product?.name}
              onChange={(e) => this.props.handleChange(e)}
              errorMessages={['this field is required']}
              label="Product Name"
              validators={['required', 'minStringLength: 4']}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={4} md={2}>
          <FormControl fullWidth>
            <TextField
              type="text"
              name="code"
              id="standard-basic"
              defaultValue={this.props.product?.code}
              onChange={(e) => this.props.handleChange(e)}
              errorMessages={['this field is required']}
              label="Product code"
              validators={['required', 'minStringLength: 4']}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={4} md={2}>
          <FormControl fullWidth>
            <TextField
              type="number"
              name="mrp"
              id="standard-basic"
              defaultValue={this.props.product?.mrp}
              onChange={(e) => this.props.handleChange(e)}
              errorMessages={['this field is required']}
              label="Product MRP"
              validators={['required', 'minStringLength: 4']}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={4} md={2}>
          <FormControl fullWidth>
            <TextField
              type="number"
              name="nlc"
              id="standard-basic"
              defaultValue={this.props.product?.nlc}
              onChange={(e) => this.props.handleChange(e)}
              errorMessages={['this field is required']}
              label="Product NLC"
              validators={['required', 'minStringLength: 4']}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4} md={2}>
          <FileView url={displayer.absolutingImageURl(this.props.product.image)} />
          <UploadFile
            filename="Image"
            displayTosection={false}
            areaName=""
            type="product_image"
            projectId="0"
            areaId="0"
            productIndex={index} // Pass the product index to the component
            callback={(File) => this.props.handleFile(File)} // Pass the index to the callback
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }
}

export default AddBulkProducts;
