import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import displayer from 'app/helpers/displayer';

class Midnav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: ''
    };
  }

  componentDidMount() {
    const queryParams = displayer.getUrlParams('/navigateto/');
    console.log(queryParams);
    this.setState({
      type: queryParams[0]
    });
  }

  render() {
    const { type } = this.state;

    return <Navigate to={`/crud/${type}`} />;
  }
}

export default Midnav;
