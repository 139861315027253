import React from 'react';
import config from '../constants/index.jsx';
import auth from './authorization.jsx';
import data from './staticdata.jsx';
import Postie from './postie.jsx';
import Datahelper from './staticdata.jsx';

const amountReadable = (amount) => {
  return amount ? amount.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : 0.0;
};

const abrivatedName = (nameString) => {
  let data = nameString.split(' ');
  let nameDisp = data[0].trim().substring(0, 1);
  if (data[1]) {
    nameDisp += data[1].trim().substring(0, 1);
  }
  return nameDisp.toUpperCase();
};
const absolutingImageURl = (src) => {
  if (src && src.indexOf('data:') !== -1) {
    return src;
  } else if (src && src.indexOf('http') !== -1) {
    return src;
  } else return config.FILES_API_URL + src;
};

const getUrlParams = (urlPath) => {
  const urlString = window.location.pathname;
  const queryString = urlString.toLowerCase().replace(urlPath.toLowerCase(), '');
  // console.log(queryString);
  const params = queryString.split('/');
  return params;
};

const floorTile = (len, wid) => {
  return len * wid;
};

const wallPaint = (len, wid, ht) => {
  let halfPerimeter = parseFloat(len) + parseFloat(wid);
  return 2 * halfPerimeter * parseFloat(ht);
};

const fcCove = (len, wid) => {
  let halfPerimeter = parseFloat(len) + parseFloat(wid);
  return 2 * halfPerimeter;
};

const fCArea = (len, wid) => {
  let halfPerimeter = parseFloat(len) + parseFloat(wid);
  return 4 * halfPerimeter;
};

const displayNumberList = (longString, delimator) => {
  let listData = longString.split(delimator);
  return (
    <ol>
      {listData.map((list) => (
        <li>{list}</li>
      ))}
    </ol>
  );
};

const displayList = (longString, delimator) => {
  let listData = longString.split(delimator);
  return (
    <ul>
      {listData.map((list) => (
        <li>{list}</li>
      ))}
    </ul>
  );
};

const skirtingArea = (len, wid) => {
  let halfPerimeter = parseFloat(len) + parseFloat(wid);
  return amountReadable((0.66 * halfPerimeter).toFixed(2));
};

const dateFormater = (dat) => {
  if (dat) {
    let t = dat.split(/[- :]/);
    let ndate = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
    var options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hours: 'numeric',
      minutes: 'numeric'
    };
    ndate.setHours(ndate.getHours() + 5, ndate.getMinutes() + 30);
    return ndate.toLocaleTimeString('en-us', options);
  } else {
    return '';
  }
};
const dateDiffer = (start, end) => {
  let start_date = new Date(start);
  let end_date = new Date(end);
  let diff = new Date(start_date - end_date);
  return (diff / 1000 / 3600 / 24).toFixed(2);
};

const currentDate = (type = 0) => {
  let date = new Date();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (type === 0) {
    return (
      date.getFullYear() +
      '-' +
      (month < 10 ? '0' + month : month) +
      '-' +
      (day < 10 ? '0' + day : day)
    );
  } else {
    return (
      (month < 10 ? '0' + month : month) +
      '/' +
      (day < 10 ? '0' + day : day) +
      '/' +
      date.getFullYear()
    );
  }
};

const areaCalc = (len, width, qty, unit, formula) => {
  let area = (parseFloat(len) * parseFloat(width)).toFixed(2) + ' ' + unit;
  if (unit === 'No.s') {
    area = 'Qty:' + qty;
  } else if (unit === 'L.S') {
    area = 'L.S.';
  } else if (formula.toLowerCase() === 'fc_area' || formula.toLowerCase() === 'fc_cove') {
    area = qty + ' ' + unit;
  }
  return area;
};

const getValidTabs = async (project) => {
  let Tabs = [
    { id: '5', name: 'Presales' },
    { id: '7', name: 'DOP-Draughting' },
    { id: '9', name: 'DOP-Scope Matrix' },
    { id: '2', name: 'DOP-First_Set_Render' },
    { id: '13', name: 'DOP-Final_Set_Render' },
    { id: '8', name: 'Workscope' },
    { id: '14', name: 'Execution' },
    { id: '11', name: 'Tasks' },
    { id: '4', name: 'Inspiration' },
    { id: '6', name: 'Estimates' },
    { id: '12', name: 'Stage_Stage_PEC' },
    { id: '10', name: 'Progress Report' },
    { id: '21', name: 'Kick off' },
    { id: '22', name: 'Final upload' },
    { id: '3', name: 'Files' }
  ];
  const ACL = {
    bde: ['1', '11', '3'],
    lead: ['1', '11', '3'],
    am: ['1', '11', '3'],
    he: ['1', '11', '3'],
    id: ['1', '11', '3'],
    colabrator: ['1', '11'],
    da: ['1'],
    dm: ['1'],
    bm: ['1', '11', '3'],
    sm: ['1'],
    om: ['1', '11', '3'],
    admin: ['1', '11', '3'],
    apm: ['1', '11', '3'],
    apm_modular: ['1'],
    jr_id: ['1'],
    tech: ['1', '11', '3']
  };
  const stages = data.getStages();
  //console.log('stages',stages);
  //console.log('stageCondition',config.BASE_API_URL+'configuration/tabcondition');
  const stageCondition = await Postie.sendAsynchronusCall(
    { authToken: localStorage.getItem(config.Token_name) },

    config.BASE_API_URL + 'configuration/tabcondition'
  );

  let requestor = auth.getUserProjectRole(project.colabrators);
  //console.log('requestor',requestor);

  const tabCheck = requestor ? ACL[requestor.role] : [];
  //console.log('tabCheck',tabCheck);

  stageCondition.data.forEach((cond) => {
    //console.log('cond:',cond.condition,'project status:',project.project_status,"cond:",cond.condition.includes(parseInt(project.project_status)));
    if (cond.condition.includes(parseInt(project.project_status))) {
      let condData = cond.acl[requestor.role];
      if (condData.length > 0) {
        condData.forEach((d) => {
          tabCheck.push(d);
        });
      }
    }
  });
  //console.log('tabCheck',tabCheck);

  const Tabdata = [];
  if (tabCheck && tabCheck.length > 0) {
    Tabs.forEach((tab) => {
      if (tabCheck.includes(tab.id)) {
        Tabdata.push(tab);
      }
    });
  }
  //console.log('Tabdata',Tabdata);

  return Tabdata;
};
//'BDE','AM','HE','Lead','SM',"ID","Jr_ID","DM","DA","Colabrator","APM","APM_Modular","BM","OM","tech"
const checkFunctionalityAccess = (project, tab, actionFunction) => {
  //console.log('insidecheckFunctionalityAccess',project,tab,actionFunction)
  const Validation = {
    presales: {
      overview: {
        editProject: ['bde', 'am', 'he', 'lead', 'tech', 'admin', 'bm']
      },
      presales: {
        addArea: ['bde', 'am', 'he', 'lead', 'tech', 'admin', 'bm'],
        updateArea: ['bde', 'am', 'he', 'lead', 'tech', 'admin', 'bm'],
        activeStatus: ['bde', 'am', 'he', 'lead', 'tech', 'admin', 'bm'],
        saveDimensions: ['bde', 'am', 'he', 'lead', 'tech', 'admin', 'bm'],
        editReferenceImages: ['bde', 'am', 'he', 'lead', 'tech', 'admin', 'bm'],
        uploadRefrenceImages: ['bde', 'am', 'he', 'lead', 'tech', 'admin', 'bm']
      }
    },
    dop: {
      overview: {
        editProject: []
      },
      presales: {
        addArea: [],
        updateArea: [],
        activeStatus: [],
        saveDimensions: [],
        editReferenceImages: [],
        uploadRefrenceImages: []
      }
    },
    execution: {
      overview: {
        editProject: []
      },
      presales: {
        addArea: [],
        updateArea: [],
        activeStatus: [],
        saveDimensions: [],
        editReferenceImages: [],
        uploadRefrenceImages: []
      }
    },
    handover: {
      overview: {
        editProject: []
      },
      presales: {
        addArea: [],
        updateArea: [],
        activeStatus: [],
        saveDimensions: [],
        editReferenceImages: [],
        uploadRefrenceImages: []
      }
    }
  };

  const stages = Datahelper.getStages();
  let projectStage = 'presales';
  if (
    parseInt(project.project_status) >= parseInt(stages.PRESALES.BOQ_approved) &&
    parseInt(project.project_status) < parseInt(stages.PREEX.Kick_off)
  ) {
    projectStage = 'dop';
  } else if (
    parseInt(project.project_status) > parseInt(stages.PREEX.Data_colation) &&
    parseInt(project.project_status) < parseInt(stages.Exe.Exe_Snags)
  ) {
    projectStage = 'execution';
  } else if (
    parseInt(project.project_status) > parseInt(stages.Exe.Exe_Handover_with_snags) &&
    parseInt(project.project_status) === parseInt(stages.handover.Handover)
  ) {
    projectStage = 'handover';
  }
  //console.log('projectStage', projectStage ,Validation[projectStage]);

  const currentRole = auth.getUserProjectRole(project.colabrators);
  //console.log('currentRole', currentRole.role,Validation[projectStage][tab][actionFunction],Validation[projectStage][tab][actionFunction].includes(currentRole.role))
  return Validation[projectStage][tab][actionFunction].includes(currentRole.role);
};

const displayer = {
  amountReadable,
  abrivatedName,
  absolutingImageURl,
  floorTile,
  wallPaint,
  fcCove,
  fCArea,
  skirtingArea,
  displayNumberList,
  displayList,
  dateFormater,
  dateDiffer,
  currentDate,
  areaCalc,
  getValidTabs,
  checkFunctionalityAccess,
  getUrlParams
};

export default displayer;
