import React, { Component } from 'react';
import { Box, Grid } from '@mui/material';

import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import FileView from '../bids/components/fileView';
import { Fragment } from 'react';
import UploadFile from '../files/uploadFile';
import AddVendor from './Components/addVendor-old';
import Postie from '../../helpers/postie';
import config from '../../constants/index.jsx';
import ProductViewTab from './Components/productViewTab';
import displayer from 'app/helpers/displayer';
import Auth from 'app/helpers/authorization';
import Connection from '../crudoperations/connection';

function getSteps() {
  let steps = ['Product View', 'Add Vendor', 'Add Brands'];
  return steps;
}

class ProductView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      skp_File: null, // To store the uploaded SKP file
      base_Image: null, // To store the uploaded internal image
      is_finished: false,
      Types: [],
      formData: {
        id: '',
        brand: '',
        name: '',
        type: '',
        width: '',
        depth: '',
        height: '',
        description: '',
        categories: []
      }
    };
  }
  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/arena/product/view/');
    this.loadData(queryParams[0]);
  };
  loadData = async (id) => {
    let productDetails = await this.loadProductDetails(id);
    productDetails.details.product_categories = productDetails.details.product_categories
      ? JSON.parse(productDetails.details.product_categories)
      : [];
    let connectedBrands = await this.loadConnectedBrands(id);
    this.setState({
      ...this.state,
      productDetails,
      connectedBrands
    });
  };
  loadProductDetails = async (id) => {
    const Url = config.BASE_API_URL + 'arena/product/detail/' + id + '/' + Auth.urlTokenizer();
    // console.log(Url);
    let jsonData = Postie.fetchUrl(Url);
    return jsonData;
  };

  loadConnectedBrands = async (id) => {
    const Url = config.BASE_API_URL + 'arena/connected/brands/' + id + '/' + Auth.urlTokenizer();

    //console.log(Url);
    let jsonData = Postie.fetchUrl(Url);
    return jsonData;
  };
  handleInputChange = async (event) => {
    const { name, value } = event.target;
    this.updateProduct(name, value);
  };

  handleCategoryChange = (data, field) => {
    console.log('handleCategoryChange called', field);
    this.updateProduct(field, JSON.stringify(data));
  };

  handleFile = (resp, type) => {
    if (type === 'skp_file') {
      this.setState(
        (prevState) => ({
          ...prevState,
          skp_File: resp.data.url
        }),
        () => {
          this.updateProduct(type, resp.data.url);
        }
      );
    } else if (type === 'base_image') {
      this.setState(
        (prevState) => ({
          ...prevState,
          base_Image: resp.data.url
        }),
        () => {
          this.updateProduct(type, resp.data.url);
        }
      );
    }
  };

  updateProduct = async (ename, evalue) => {
    const { productDetails } = this.state;
    const authToken = localStorage.getItem(config.Token_name);
    const postData = {
      authToken,
      product_id: productDetails.details.id,
      field: ename,
      value: evalue
    };
    // console.log('update product', postData, this.state);
    let serviceUrl = config.BASE_API_URL + 'arena/update_product';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status !== 'success') {
      Postie.showAlert(reply);
    }
  };

  makeLink = async (childId) => {
    let serviceUrl = config.BASE_API_URL + 'arena/add_link';
    const postData = {
      authToken: localStorage.getItem(config.Token_name),
      product_id: this.state.productDetails.id,
      child_id: childId
    };
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      this.setState({ ...this.state, connectedProducts: reply.data }, function () {
        //console.log('this.', this.state);
      });
    } else {
      Postie.showAlert(reply);
    }
  };

  handleNext = async () => {
    this.setState((prevState) => ({ activeStep: prevState.activeStep + 1 }));
    const { formData } = this.state;
    const authToken = localStorage.getItem(config.Token_name);
    const postData = {
      authToken,
      type: formData.type,
      brand: formData.brand,

      name: formData.username,
      width: formData.width,
      depth: formData.depth,
      height: formData.height,
      description: formData.description,
      categories: JSON.stringify(formData.categories)
    };

    console.log('Post Data:', postData);
    let serviceUrl = config.BASE_API_URL + 'arena/create_product';
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      let Url = '/arena/product/view';
      console.log('productView', Url);
      Postie.openLink(Url);
    } else {
      Postie.showAlert(reply);
    }
  };

  handleBack = () => {
    this.setState((prevState) => ({ activeStep: prevState.activeStep - 1 }));
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  handleStepClick = (stepIndex) => {
    this.setState({ activeStep: stepIndex });
  };
  openProductView = (id) => {
    let url = '/arena/product/view/' + id;
    //console.log(url);
    Postie.navigateLink(url);
  };

  renderStepContent = (stepIndex) => {
    const { skp_File, base_Image } = this.state;
    const { productDetails, connectedBrands } = this.state;
    const { product } = this.props; // Access the product prop
    switch (stepIndex) {
      case 0:
        return (
          <Fragment>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <ProductViewTab
                  key={productDetails}
                  title={`Product Details (${productDetails?.details?.id}): `}
                  product={productDetails?.details}
                  hideButton={true}
                  handleCategoryChange={(data, field) => this.handleCategoryChange(data, field)}
                  handleInputChange={(event) => this.handleInputChange(event)}
                />
              </Grid>
              <Grid item xs={4} style={{ padding: '80px' }}>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    {productDetails?.details?.skp_file ? (
                      <FileView
                        key={productDetails?.details?.skp_file}
                        id={productDetails?.details?.id}
                        //url={displayer.absolutingImageURl(productDetails?.skp_file)}
                        url={
                          skp_File ||
                          displayer.absolutingImageURl(productDetails?.details?.skp_file)
                        }
                      />
                    ) : (
                      <FileView
                        key={`noskp`}
                        id={productDetails?.details?.id}
                        url="skp_File.NO-SKP"
                      />
                    )}
                    <UploadFile
                      filename="SKP File"
                      displayTosection={false}
                      areaName=""
                      type="skp_file"
                      projectId="0"
                      areaId="0"
                      callback={(File) => this.handleFile(File, 'skp_file')}
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    {productDetails?.details?.base_image ? (
                      <FileView
                        key={productDetails?.details?.base_image}
                        id={productDetails?.details?.id}
                        //url={displayer.absolutingImageURl(productDetails?.base_image)}
                        url={
                          base_Image ||
                          displayer.absolutingImageURl(productDetails?.details?.base_image)
                        }
                      />
                    ) : (
                      <FileView
                        key={`nobaseImage`}
                        id={productDetails?.details?.id}
                        url={'product.No_base_image'}
                      />
                    )}
                    <UploadFile
                      filename="Image"
                      displayTosection={false}
                      areaName=""
                      type="product_image"
                      projectId="0"
                      areaId="0"
                      callback={(File) => this.handleFile(File, 'base_image')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        );

      case 1:
        return (
          <Fragment>
            <AddVendor key={productDetails} productId={productDetails?.details?.id} />
          </Fragment>
        );

      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              {console.log('children:', productDetails)}
              <Connection
                key={productDetails.children['brands']}
                heading={`Brands`}
                fields={productDetails.children['brands'].fields}
                data={productDetails.children.brands.data}
                apiCalls={productDetails.children['brands'].apiCalls}
                connectionId={productDetails?.details?.id}
                callback={() => this.loadData(productDetails?.details?.id)}
              />
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  render() {
    const { activeStep } = this.state;
    const steps = getSteps();

    return (
      <Box>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} onClick={() => this.handleStepClick(index)}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box
          mt={4}
          style={{
            marginLeft: '20px'
          }}
        >
          <Box>{this.renderStepContent(activeStep)}</Box>
        </Box>
      </Box>
    );
  }
}

export default ProductView;
