import React, { Component } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import Postie from '../../helpers/postie.jsx';
import config from '../../constants/index.jsx';
import Auth from 'app/helpers/authorization';

class ApproveLeadPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: ''
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ ...this.state, reason: value });
  };

  handleSumbitReason = async () => {
    const postData = {
      authtoken: Auth.getToken(),
      user_id: this.props.lead?.id,
      reason: this.state.reason
    };
    const url = config.BASE_API_URL + 'admin/rejectuser';
    let jsonData = await Postie.sendAsynchrnousCall(postData, url);
    if (jsonData.status === 'success') {
      this.props.closePopup();
    }
  };

  render() {
    const { lead, type } = this.props;
    return (
      <Dialog
        open={this.props.openPopup}
        //onClose={this.toggleDialog}
        fullWidth
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle>{type === 'approve' ? 'Approving' : 'Rejection'} Transection</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <p>
                <strong>User:</strong> John Smith
              </p>
              <p>
                <strong>Date:</strong> 01-8-2024
              </p>
              <p>
                <strong>Status:</strong> Pending
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label>
                <strong>Payment Details</strong>
              </label>
              <p>
                <strong>Bank Name:</strong> ABC Bank
              </p>
              <p>
                <strong>IFSC:</strong> ABC00000084
              </p>
              <p>
                <strong>Bank Address:</strong> Goa
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'right' }}>
              <h3>₹ 3000.00</h3>
            </Grid>
          </Grid>
          {type === 'approve' && (
            <Grid>
              <label>Transection ID</label>
              <TextField
                type="text"
                label="Write reason"
                name="reason"
                onChange={this.handleInputChange}
                style={{ width: '90%' }}
              />
            </Grid>
          )}
          <Grid>
            <label>Comments</label>
            <TextField
              type="text"
              label="Write reason"
              name="reason"
              onChange={this.handleInputChange}
              style={{ width: '90%' }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closePopup} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={this.handleSumbitReason} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ApproveLeadPopup;
