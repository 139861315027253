import React, { Component } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import FileView from 'app/views/bids/components/fileView';
import LoadTextField from './LoadTextField';
import {Grid} from '@mui/material';
import displayer from 'app/helpers/displayer';
import Auth from 'app/helpers/authorization';
import config from "../../../constants/index";
import Postie from 'app/helpers/postie';
import { post } from 'jquery';

/*
<UploadFile 
  Filename={"logo"}
  displayTosection={false}
  areaName={""}
  type={"logo"}
  projectId={0}
  areaId={0}

  />

 */

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  '& .closeButton': {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className="closeButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  '&.root': { padding: theme.spacing(2) }
}));

class AIReplacer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading:false,
      outputGenerated:true,
      prompt:this.props.prompt,
      outputImage:"",
      searchPrompt:this.props.searchPrompt,
      image:this.props.image,
      productImage:this.props.replaceImage,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleInputChange=(e)=>{
    const{name,value}=e.target;
    this.setState({
        ...this.state,
        [name]:value,
    })
  }
  handleGeneratePrompt=async()=>{
    this.setState({loading:true});
    let postData={
        authToken:localStorage.getItem(config.Token_name),
        url:this.props.image,
        prompt:this.state.inputText,
    };
    let serviceUrl=config.BASE_API_URL+'arena/ai/caption';
    let response=await Postie.sendAsynchrnousCall(postData,serviceUrl);
    if(response.status==="success"){
        this.setState({...this.state,outputText:response.data,outputGenerated:true});
    }else{
        Postie.showAlert(response);
    }
    this.setState({loading:false});
  }
  handleGenerateImage=async()=>{
    this.setState({loading:true,outputImage:""});
    let postData={
        authToken:localStorage.getItem(config.Token_name),
        prompt:this.state.prompt,
        search_prompt:this.state.searchPrompt,
        image:displayer.absolutingImageURl(this.state.image),
    };
    let serviceUrl=config.BASE_API_URL+'arena/ai/replace';
    let response=await Postie.sendAsynchrnousCall(postData,serviceUrl);
    if(response.status==="success"){
        this.setState({...this.state,outputImage:response.data});
    }else{
        Postie.showAlert(response);
    }
    this.setState({loading:false});
  }
  approveImage= async()=>{
    let url = config.BASE_API_URL + 'crud/detail/' + this.props.updateModel + '/' + this.props.updateId + '/' + Auth.urlTokenizer();
    let jsonData = await Postie.fetchUrl(url);
    console.log('sata', jsonData);
    if (jsonData) {
      const apiCall=jsonData.apiCalls['update'];
      console.log('approveImage apiCalls',apiCall);
      let serviceUrl = config.BASE_API_URL + apiCall.url;
      let postData={
        authToken:localStorage.getItem(config.Token_name),
        id:this.props.updateId,
        field:'image',
        value:this.state.outputImage,
      }
      let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
      if(reply.status==="success"){        
        this.handleClose();
        //this.props.callback();
      }else{
        Postie.showAlert(reply);
      }
    }
  }

  handleFileUpload = (file) => {
    let response = JSON.parse(file.xhr.response);
    if (response.status === 'success') {
      console.log('fileUploaded', response);
      this.props.callback(response);
    }
    //Postie.showAlert(response);
    this.handleClose();
  };
  render() {
    const {prompt,searchPrompt,image,productImage,outputImage,open,loading,outputGenerated}=this.state;
    return (
      <div>
        <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
          Replace Product
        </Button>

        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth="lg" // Set the maxWidth to control the width of the dialog box
          fullWidth // This ensures that the dialog takes up the full width
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Replace Product 
          </DialogTitle>
          <DialogContent dividers>
          <Grid container spacing={2} >
            <Grid item md="4" xs="6" style={{background:'#dddddd',padding:'5px 10px'}}>
                <h5>Primary Image</h5>
                <FileView  
                    id={`file-view`}
                    url={displayer.absolutingImageURl(image)}
                    style={{width:'90%',maxheight:'300px'}}/>
                <h5>Reference Image</h5>
                <FileView  
                    id={`file-view`}
                    url={displayer.absolutingImageURl(productImage)}
                    style={{width:'90%',maxheight:'300px'}}/>
            </Grid>
            <Grid item md="8" xs="6">
              <LoadTextField
                    record={{'label':""}}
                    name={'prompt'}
                    style={{ width: '90%' }}
                    type={'text'}
                    placeHolder={`Prompt`}
                    multiline={true}
                    defaultValue={prompt}
                    callback={(event) => this.handleInputChange(event)}
                />
                <LoadTextField
                    record={{'label':""}}
                    name={'searchPrompt'}
                    style={{ width: '90%' }}
                    type={'text'}
                    placeHolder={`Replace`}
                    multiline={false}
                    defaultValue={searchPrompt}
                    callback={(event) => this.handleInputChange(event)}
                />
                

                 {searchPrompt &&<Button  style={{ margin: '10px' }} variant="contained"color="primary" onClick={()=>this.handleGenerateImage()}>Replace</Button>}

                {outputImage?.length>0  && <Button  style={{ margin: '10px' }} variant="contained"color="success" onClick={()=>this.approveImage()}>Update Image</Button>}

                {loading && <h3 className='text-primary'>Loading ...</h3>}
                {outputImage.length>0 &&<FileView  
                    id={`outputImage`}
                    url={displayer.absolutingImageURl(outputImage)}
                    style={{width:'90%',maxheight:'300px'}}/>}
            </Grid>
            
          </Grid>

          </DialogContent>
                   
        </Dialog>
      </div>
    );
  }
}

export default AIReplacer;
