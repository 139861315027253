const Layout1Settings = {
  leftSidebar: {
    show: true,
    mode: 'compact', // full, close, compact, mobile,
    theme: 'purpleDark2', // View all valid theme colors inside MatxTheme/themeColors.js
    bgImgURL: '/assets/images/sidebar/sidebar-bg-dark.jpg'
  },
  topbar: {
    show: true,
    fixed: true,
    theme: 'purpleDark2' // View all valid theme colors inside MatxTheme/themeColors.js
  }
};

export default Layout1Settings;
