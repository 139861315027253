import React, { Component } from 'react';
import {
  TreeView,
  processTreeViewItems,
  handleTreeViewCheckChange
} from '@progress/kendo-react-treeview';
import { Button } from '@mui/material';
import staticData from 'app/helpers/staticdata';
import '@progress/kendo-theme-default/dist/all.css';
import Create from 'app/views/crudoperations/create';
import Postie from 'app/helpers/postie';
import config from '../../../constants/index';
import Auth from 'app/helpers/authorization';
import displayer from 'app/helpers/displayer';

{
  /*<CategoryTreeView
            key={workCategories}
            data={workCategories}
            field="sow"
            value="id"
            selected={this.props.product?.categories?{'data':this.props.product?.categories,'objectName':"category_id"}:{'data':[],'objectName':"category_id"}}
            onItemClick={(data) => this.props.handleCategoryChange(data, 'categories')}
          />*/
}

class CategoryTreeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: ['0', '4'],
      expand: {
        ids: ['Item2'],
        idField: 'name'
      },
      select: [''],
      //name: staticData.getArenaCategories()
      tree: [],
      heading: '',
      fields: '',
      data: '',
      apiCalls: '',
      children: '',
      filters: {}
    };
  }

  componentDidMount = async () => {
    const queryParams = displayer.getUrlParams('/arena/products/add/');
    this.loadData(queryParams[0]);

    const categories = this.props.data;
    //console.log('categoryData', categories);
    let tree = [];
    categories?.forEach((category) => {
      tree.push(this.getCategoryTreeFormation(category, this.props.field, this.props.value));
    });
    console.log('tree', tree, this.props.selected);
    let select = [];

    this.props.selected?.data?.length > 0 &&
      this.props.selected.data?.forEach((record) => {
        let checkedindex = this.getValueIndex(
          categories,
          this.props.selected.objectName === '' ? record : record[this.props.selected.objectName],
          ''
        );
        if (checkedindex != '') {
          select.push(checkedindex);
        }
      });
    this.setState({ tree: tree, check: select }, function () {
      console.log(this.state, tree, select);
    });
  };

  loadData = async () => {
    let userLogged = await Auth.isUserLoggedIn();
    let callType = this.props.type;
    if (userLogged) {
      let serviceUrl =
        config.BASE_API_URL + 'crud/' + callType + '/' + 1 + '/' + Auth.urlTokenizer();
      let postData = { filters: this.state.filters };
      let json = await Postie.sendAsynchrnousCall(postData, serviceUrl);

      if (json.status === 'success') {
        console.log('success called');
        this.setState(
          {
            ...this.state,
            heading: json.data.heading,
            fields: json.data.fields,
            data: json.data.data,
            apiCalls: json.data.apiCalls,
            children: json.data.children,
            addDiffrent: json.data.addDiffrent
          },
          function () {
            console.log(
              'CategoryTreeViewdata',

              this.state.fields,
              this.state.data,
              this.state.apiCalls,
              this.state.children
            );
          }
        );
      }
    }
  };
  getCategoryTreeFormation = (data, field, value) => {
    let trc = {};
    trc.text = data[field];
    trc.id = data[value];
    trc.items = [];
    if (data.child && Array.isArray(data.child) && data.child.length > 0) {
      data.child.forEach((child) => {
        trc.items.push(this.getCategoryTreeFormation(child, field, value));
      });
    }
    return trc;
  };

  getIndexValue = (herIndex, portion, data) => {
    let indexer = herIndex.split('_');
    if (indexer.length > portion + 1) {
      console.log('read code');
      return this.getIndexValue(herIndex, portion + 1, data[indexer[portion]].items);
    } else {
      return data[indexer[portion]].id;
    }
  };

  getValueIndex = (data, selectedValue, preIndex) => {
    data?.forEach((record, index) => {
      if (record.id == selectedValue) {
        preIndex += index;
      } else {
        if (record.child) {
          let nextIndex = preIndex + index + '_';
          let childIndex = this.getValueIndex(record.child, selectedValue, nextIndex);
          if (childIndex != nextIndex) {
            preIndex = childIndex;
          }
        }
      }
    });
    return preIndex;
  };

  onItemClick = (event) => {
    this.setState({
      select: [this.getIndexValue(event.itemHierarchicalIndex, 0, this.state.tree)]
    });
    console.log('Selected Item Index:', event.itemHierarchicalIndex);
  };

  onExpandChange = (event) => {
    const { expand } = this.state;
    const index = expand.ids.indexOf(event.item.sow);
    let updatedIds;

    if (index === -1) {
      // Add the clicked category to the existing expanded ids array
      updatedIds = [...expand.ids, event.item.sow];
    } else {
      // Remove the clicked category from the expanded ids array
      updatedIds = expand.ids.filter((id) => id !== event.item.sow);
    }

    this.setState({
      expand: {
        ids: updatedIds,
        idField: 'sow'
      }
    });
    console.log('expend', updatedIds, 'sow');
  };

  onCheckChange = (event) => {
    const settings = {
      singleMode: false,
      checkChildren: false,
      checkParents: false
    };

    const { check, tree } = this.state;
    //console.log('event', event);
    const updatedCheck = handleTreeViewCheckChange(event, check, tree, settings);
    //console.log('Updated Check State0:', updatedCheck);
    let constValue = [];
    updatedCheck.forEach((record) => {
      console.log('update check loop', record);
      constValue.push(this.getIndexValue(record, 0, this.state.tree));
    });

    this.setState({
      check: updatedCheck
      //checkIndexed: constValue
    });
    this.props.onItemClick(constValue);
  };
  connectTreeValue = async (apiCall, postData) => {
    console.log('apicall', apiCall);
    let url = config.BASE_API_URL + apiCall.url;
    postData = { ...postData, authToken: localStorage.getItem(config.Token_name) };
    let reply = await Postie.sendAsynchrnousCall(postData, url);
    if (reply.status === 'success') {
      console.log('connectTreeValue', reply.data.data.records);
      this.props.connectData(reply.data);
      let selectedData = this.props.selected.data;
      selectedData.push(reply.data.data.records[0].id);
      this.props.onItemClick(selectedData);
    } else {
      Postie.showAlert(reply);
    }
  };

  render() {
    const { select, check, expand, tree } = this.state;

    return (
      <div>
        {/* <Button variant="contained" color="primary">
          Add Category
        </Button> */}

        {this.state.fields && (
          <Create
            heading={'category'}
            fields={this.state.fields}
            apiCall={this.state?.apiCalls['create']}
            handleFile={(file, type) => this.props.handleFile(file, type)}
            add={(data) => this.connectTreeValue(this.state?.apiCalls['create'], data)}
            defaultValue={this.state.defaultValue}
            filters={this.state.filterType}
          />
        )}
        <TreeView
          data={processTreeViewItems(tree, { select, check, expand })}
          expandIcons={true}
          onExpandChange={this.onExpandChange}
          aria-multiselectable={true}
          checkboxes={true}
          onCheckChange={this.onCheckChange}
        />
      </div>
    );
  }
}

export default CategoryTreeView;
